import React from 'react';
import ROUTE from './route';
import AwardBadgeContainer from './container/awardBadgeContainer';
import userProfileAwardsContainer from './container/userProfileAwardsContainer';
import UseAwardTabContainer from './container/userAwardsTabContainer';
import issueNewBadgeContainer from './container/issueNewBadgeContainer';
import KudosHomeContainer from './container/kudosHomePageContainer';
import ProfileDetailContainer from 'modules/home/containers/ProfileDetailContainer';
export default [

        {
            name: 'kudosHome',
            path: ROUTE.badge_awards_view,
            component: KudosHomeContainer,
            exact: true,
            protect: true
        },
        {
            name: 'AddBadge',
            path: ROUTE.add_badge,
            component: issueNewBadgeContainer,
            exact: true,
            protect: true
        },
        {
            name:'userDetail',
            path:ROUTE.user_detail,
            component:ProfileDetailContainer,
            protect:true,
            exact:true
        }
]