import LandingContainer from "./landing/containers/landingContainer";
import SoulHomeLearningContainer from "./soul/containers/soul-home-container";
import ROUTE from "./routes";
export default [
    // {
    //     name:"LearningPathLanding",
    //     path:ROUTE.learning_path,
    //     component:LandingContainer,
    //     protect:true,
    //     exact:true
    // },
    // {
    //     name:"SoulLearningPath",
    //     path:ROUTE.soul_learning_home,
    //     component: SoulHomeLearningContainer,
    //     protect:true,
    //     exact:true
    // },
    {
        name:"SoulLearningPath",
        path:ROUTE.soul_learning_path,
        component: SoulHomeLearningContainer,
        protect:true,
        exact:true
    }
]