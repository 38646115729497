// @ts-nocheck
import { Button, Row, Col, Input, message } from "antd";
import { AddButton, BackButton, CustomProgress, PageTitle } from "modules/look-v2";
import React from "react";
import ObjectiveDetailTableView from "./ObjectiveDetailTableView";
import discrd_logo from '../../assets/discrd_logo.svg'
import discard_disabled from '../../assets/discard-disabled.svg'
import delete_outline_24px from '../../assets/delete_outline_24px.svg'
import delete_disabled from '../../assets/delete-disabled.svg'
import RequestDiscardView from "./requestDiscardView";
import RequestCancelDiscard from './RequestCancelView'
import edit_pencil from "../../assets/edit_pencil.svg";
import checkCircle from "../../assets/checkCircle.svg";
import { Capitalize, DatePickerField, MetaTags, changeToCurrentTime, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import styled from "styled-components";
import moment from "moment";
import ChatBox from "modules/look-v2/Components/Chatbox";
import Ellipse_red from '../../assets/Ellipse_red.svg'
import Ellipse_green from '../../assets/Ellipse_green.svg'
import Ellipse_blue from '../../assets/Ellipse_blue.svg'
import AddWeightButtonContainer from "../formComponents/Container/AddWeightButtonContainer";
import DeleteOkrDetails from "./deleteOkrDetails.";
import brown_circle from '../../assets/brown_circle.svg'
import { TableDiv } from "./KeyResultDetailView";
import UpdateCorrectionDeadlineModal from "modules/look-v2/Components/UpdateCorrectionDeadlineModal";
import ObjectiveTreeContainer from "../container/ObjectiveTreeContainer";
import okr_route from "../route";
import OkrAiTipContainer from "modules/ai-tips/container/okr-ai-tip-container";


const ObjectiveDetailView = (props) => {
  const { okrById, allKr, deleteOkr, updateOkr, me, navigateRoute, refetchOkr, entityHeadRoles, permission, allokrweights, getWightList,okrPermitted } = props
  const [addKr, setAddKr] = React.useState(false);
  const [okrId, setOkrId] = React.useState();
  const [editableInputBox, setEditableInputBox] = React.useState(false);
  const [discardPopupvisibleOBJ, setDiscardPopupVisibleOBJ] = React.useState(false);
  const [discardCancelPopupvisible, setDiscardCancelPopupVisible] = React.useState(false);
  const [goalStatement, setGoalStatement] = React.useState();
  const [discardOKRDetail, setdiscardOKRDetail] = React.useState();
  const [duedate, setDueDate] = React.useState();
  const [correctionDeadline, setCorrectionDeadline] = React.useState();
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [isOwner, setIsOwner] = React.useState(false);
  const [isAssignedOkr, setIsAssignedOkr] = React.useState(false);
  const [weightButtonDisabled, setWeightButtonDisabled] = React.useState(false);
  const [okrDiscarded, setOkrDiscarded] = React.useState(false);
  const [assignedBy, setAssignedBy] = React.useState();
  const [owner, setOwner] = React.useState('');
  const [assignedUser, setAssignedUser] = React.useState('');


  const [width, setWidth] = React.useState(window.innerWidth);
  const [okr_tree, set_okr_tree] = React.useState();

  const DeleteSelected = async (okrId) => {
    let response = await deleteOkr({ id: okrId })
    if (response) {

    }
  }

  const updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  }


  React.useEffect(() => {

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions)

  })



  React.useEffect(() => {
    if (okrById) {
      localStorage.removeItem('emailRoute')
      setEditableInputBox(false)
      setGoalStatement(okrById?.goalStatement);
      setDueDate(okrById?.duedate);
      let isOkrDiscarded = okrById?.isDiscarded?.id;
      if (isOkrDiscarded) {
        setOkrDiscarded(true);
      }

      if (me && getIntFromString(okrById?.owner?.user?.id) === getIntFromString(me?.id)) {
        setIsOwner(true);
      }

      let contributors = okrById?.contributorSet?.edges?.filter((element) =>
        getIntFromString(element?.node?.employee?.id) === getIntFromString(me?.employee?.id)
      );

      if (contributors.length && contributors?.find(({ node }) => node?.role !== "OWNER" && node?.employee?.id == me?.employee?.id)) {
        setIsAssignedOkr(true);
      }

      let assignedbyUser = okrById?.contributorSet?.edges?.filter((element) =>
        element?.node?.role === 'OWNER'
      )
      setAssignedBy(assignedbyUser);
      let isDueDateAlive = moment(okrById?.duedate).isAfter(moment());

      let isCorrectionDateAlive = moment(okrById?.correctionDeadline).isAfter(moment())

      if (isCorrectionDateAlive && isDueDateAlive) {
        setCorrectionDeadline(true);
      } else {
        setCorrectionDeadline(false);
      }

      let owner = `${Capitalize(okrById?.owner?.user?.firstName)}` + ' ' + `${Capitalize(okrById?.owner?.user?.lastName)}`
      setOwner(owner);

      let assignedPerson = `${assignedbyUser[0]?.node?.employee?.user?.firstName}` + ' ' + `${assignedbyUser[0]?.node?.employee?.user?.lastName}`
      setAssignedUser(assignedPerson);
    }
  }, [okrById, me])

  const handleSubmit = async (e) => {
    if (duedate && goalStatement) {
      let due_date = changeToCurrentTime(duedate)
      let response = await updateOkr({ id: okrById?.id, duedate: due_date, goalStatement: goalStatement, startdate: okrById?.startdate })
      if (response) {
        // setEditableInputBox(false);
        refetchOkr()
      }
    } else {
      if (!goalStatement) {
        message.error('goal statement missing')
      } else if (!duedate) {
        message.error('due date missing')
      }
    }

  }

  const updateDiscardRow = (record) => {
    setDiscardPopupVisibleOBJ(false);
    setdiscardOKRDetail(null)
  }


  const editOkr = () => {
    if (correctionDeadline) {
      setEditableInputBox(true)
    }

  }

  const renderRequestAccpetStatus = () => {
    let contributor = okrById?.contributorSet?.edges?.filter(({ node }) => node?.employee?.id === me?.employee?.id)
    if (!contributor?.length) {
      return ('')
    }
    let current_status
    if (contributor?.length > 1) {
      current_status = contributor.find(({ node }) => node?.role === 'OWNER')?.node?.requestStatus
    }
    else {
      current_status = contributor[0]?.node?.requestStatus
    }
    let color = current_status === 'PENDING' ? '#CB861F' : current_status === 'ACCEPTED' ? '#4CCB1F' : current_status === 'NEEDS_DISCUSSION' ? '#009AF1' : ''
    let status = current_status === 'PENDING' ? 'Pending' : current_status === 'ACCEPTED' ? 'Accepted' : current_status === 'NEEDS_DISCUSSION' ? 'Needs Discussion' : ''
    let icon = current_status === 'PENDING' ? brown_circle : current_status === 'ACCEPTED' ? Ellipse_green : current_status === 'NEEDS_DISCUSSION' ? Ellipse_blue : null
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && (<img src={icon} style={{ paddingRight: '10px' }} alt="" />)}
        <div style={{ color: color, fontWeight: '600', fontSize: '18px', whiteSpace: "nowrap" }}>{status}</div>
      </div>
    )
  }

  const checkRequestStatus = () => {
    if (!okrById) {
      return false
    }
    let contributor = okrById?.contributorSet?.edges?.filter(({ node }) => node?.employee?.id === me?.employee?.id)
    if (!contributor?.length) {
      return false
    }
    let current_status
    if (contributor?.length > 1) {
      current_status = contributor.find(({ node }) => node?.role === 'OWNER')?.node?.requestStatus
    }
    else {
      current_status = contributor[0]?.node?.requestStatus
    }
    return current_status === 'ACCEPTED' ? true : false
  }

  return (
    <>
      <MetaTags title="OKR" description="OKR Home" />
      {okr_tree && (<ObjectiveTreeContainer okr={okr_tree} onClose={() => { set_okr_tree(null) }} {...props} />)}
      {/* title section */}
      <div className='okr-detail-titile-section okr-detail-main-layout' >
        <BackButton detailArrow={true} />
        <div style={{ display: "flex", gap: "1em", justifyContent: 'center', alignItems: "center" }}>
          {
            (editableInputBox && correctionDeadline) ?
              <Input autoComplete='off' defaultValue={okrById?.goalStatement} type='text' className='okr-detail-page-title okr-detail-input-box' onChange={(e) => setGoalStatement(e.target.value)} />
              :
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "0.75em", width: "fit-content", flexWrap: "nowrap" }}>
                <h4 className="okr-detail-page-title">{okrById?.goalStatement}</h4>
                {
                  !editableInputBox && correctionDeadline && !okrDiscarded && !(okrById?.isDiscarded?.status || okrById?.pending?.totalCount > 0) && isOwner ?
                    <img src={edit_pencil} onClick={(e) => setEditableInputBox(true)} style={{ cursor: "pointer", width: "1.5em" }} />
                    :
                    null

                }
              </div>
          }
          {
            editableInputBox && !okrDiscarded && isOwner &&
            <img src={checkCircle} style={{ cursor: "pointer", width: "2.5em" }} onClick={(e) => handleSubmit(e)} />
          }

          {/* {
                      !editableInputBox && correctionDeadline && !okrDiscarded && (okrById?.isDiscarded?.status || okrById?.pending?.totalCount > 0) &&
                      <img src={edit_discard} style={{ cursor: "pointer" }} />
                    } */}

        </div>
        <Row align="middle" justify="space-between" style={{ paddingRight: "0px", flexWrap: "nowrap", gap: ".25em" }}>
          {
            (okrById?.discardRequestRaised.totalCount > 0) ?
              <img className="details-page-logos" src={discard_disabled} /> :
              <img className="details-page-logos" src={discrd_logo} onClick={(e) => setDiscardPopupVisibleOBJ(true)} />
          }

          {
            isOwner && (
              (okrById?.discardRequestRaised.totalCount || moment(okrById?.correctionDeadline).isBefore(moment())) ?
                <img className="details-page-logos" src={delete_disabled} /> :
                <img className="details-page-logos" src={delete_outline_24px} onClick={(e) => setDeleteRecord(true)} />
            )
          }
        </Row>
      </div>


      {
        okrById && (
          <Row style={{ gap: "20px" }}>

            {
              (okrPermitted && okrPermitted?.runpod_permission) && (
                <OkrAiTipContainer {...props} />
              )
            }
            <div className="okr-detail-main-layout">
              <div className="okr-detail-status-container">

                <div className="okr-detail-status-sub-container" >
                  {/* due date section */}
                  <div className='font-family-poppins okr-detail-status-section' >
                    <h4 className='color-gray'>Due Date</h4>
                    {
                      (editableInputBox && correctionDeadline) ?
                        <DatePickerField
                          defaultValue={moment(okrById?.duedate)}
                          onChange={(e) => setDueDate(e)}
                        /> :
                        <h4 >{displayUtcToUserTimezone(okrById?.duedate, 'DD MMM YYYY')} </h4>
                    }
                  </div>

                  {/* status section */}
                  <div className='font-family-poppins okr-detail-status-section'>
                    <h4 className='color-gray'>Status</h4>
                    {
                      !okrById?.isDiscarded && okrById?.pending.totalCount && okrById?.disapproved.totalCount === 0 ?
                        <div style={{ display: 'flex', alignItems: "center", width: '100%' }}>
                          <img src={Ellipse_blue} style={{ paddingRight: '10px', width: '23px' }} />
                          <h4 style={{ color: '#009AF1', fontWeight: '600', fontSize: '16px' }}>Discard Requested</h4>
                        </div>
                        :
                        !okrById?.isDiscarded && okrById?.pending.totalCount && okrById?.disapproved.totalCount ?
                          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <img src={Ellipse_blue} style={{ paddingRight: '10px', width: '23px' }} />
                            <h4 style={{ color: '#E86161', fontWeight: '600', fontSize: '16px' }}>Discard Requested</h4>
                          </div>
                          :
                          !okrById?.isDiscarded && okrById?.pending.totalCount === 0 && okrById?.disapproved.totalCount ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Col>
                                <Row align="middle" justify="center"  >
                                  <img src={Ellipse_green} style={{ paddingRight: '10px' }} />
                                  <h4 style={{ color: '#4CCB1F', fontWeight: '600', fontSize: '18px' }}>Active</h4>
                                </Row>
                                <h4 align='center' style={{ fontSize: '14px' }}>(Request Disapproved)</h4>
                                <div align='center' style={{ fontSize: '12px', color: "#009AF1", cursor: "pointer" }}

                                  onClick={(e) => navigateRoute(okr_route.discardDetail.replace(":id", getIntFromString(okrById?.disapproved?.edges[0]?.node?.id)))}
                                >
                                  (See Details)</div>
                              </Col>

                            </div>
                            :
                            okrById?.isDiscarded?.status === "APPROVED" ?
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Ellipse_red} style={{ paddingRight: '10px' }} />
                                <h4 style={{ color: '#E86161', fontWeight: '600', fontSize: '18px' }}>Discarded</h4>
                              </div>
                              :
                              okrById?.contributorSet?.edges.length ?
                                renderRequestAccpetStatus()

                                : null
                    }


                  </div>
                </div>

                {/* progress section */}
                <div className='font-family-poppins okr-detail-status-section okr-status-progress-section '>
                  <h4 className='color-gray'>Progress</h4>
                  <CustomProgress progress={`${okrById?.progress}`} lineHeight={1.8} info={true} />
                </div>

                <div className="okr-detail-status-sub-container">
                  {/* owner section */}
                  <div className='font-family-poppins okr-detail-status-section'>
                    <h4 className='color-gray'>Owner</h4>
                    <div align='center' className="poppins" style={{ fontWeight: '500', fontSize: '16px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "150px" }}>
                      {owner}
                    </div>
                  </div>

                  {/* assigned okr section */}
                  {
                    isAssignedOkr &&
                    <div className='font-family-poppins okr-detail-status-section'>
                      <h4 className='color-gray'>Assigned By</h4>
                      <div align='center' className="poppins" style={{ fontWeight: '500', fontSize: '16px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "150px" }}>{assignedUser}</div>
                    </div>
                  }
                </div>

              </div>


              {/* discard request detail section */}
              {
                (okrById?.isDiscarded?.id || okrById?.pending?.totalCount > 0) &&
                <Row align='middle' justify="center" style={{
                  marginTop: '50px', background: '#FFFFFF',
                  boxShadow: '0px 4px 42px 8px rgba(0, 0, 0, 0.07)',
                  borderRadius: '17px',
                  padding: "20px"
                }}>
                  <Col style={{ marginTop: '20px' }} span={16}>
                    <Row align='middle' justify="center">
                      <Col span={24}>
                        {
                          okrById?.isDiscarded?.status === "APPROVED" ?
                            <div align='center' style={{ fontSize: '20px' }} className='objective-title'>Discard Request APPROVED</div>
                            :
                            okrById?.pending?.totalCount > 0 ?
                              <div align='center' style={{ fontSize: '20px' }} className='objective-title'>Discard Request</div>

                              : null
                        }
                      </Col>
                    </Row>
                    {okrById?.isDiscarded?.id && <p className='discard-subhead' style={{ color: '#E86161', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center' }} onClick={() => navigateRoute(okr_route.discardDetail.replace(":id", getIntFromString(okrById?.isDiscarded?.id)))}>See Details</p>}
                    {okrById?.pending?.totalCount > 0 && <p className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center' }} onClick={() => navigateRoute(okr_route.discardDetail.replace(":id", getIntFromString(okrById?.pending?.edges[0]?.node?.id)))}>See Details</p>}
                    <Row align="middle" justify='space-around'>
                      <Col>
                        <div className='discard-small-head'>
                          Created By
                        </div>
                        <div className='discard-small-data'>
                          {okrById?.pending?.totalCount > 0 &&
                            `${<span className="text-transform-capitalize">{okrById?.pending?.edges[0]?.node?.initiator?.user?.firstName}</span>}` + ' ' + `${<span className="text-transform-capitalize">{okrById?.pending?.edges[0]?.node?.initiator?.user?.lastName}</span>}`
                          }
                          {okrById?.isDiscarded?.id &&
                            `${<span className="text-transform-capitalize">{okrById?.isDiscarded?.initiator?.user?.firstName}</span>}` + ' ' + `${<span className="text-transform-capitalize">{okrById?.isDiscarded?.initiator?.user?.lastName}</span>}`
                          }
                        </div>

                      </Col>
                      <Col>
                        <p className='discard-small-head'>
                          Created On
                        </p>
                        <h4 className='discard-small-data'>
                          {okrById?.isDiscarded?.id && displayUtcToUserTimezone(okrById?.isDiscarded?.createdAt, 'DD MMM YYYY')}
                          {okrById?.pending?.totalCount > 0 && displayUtcToUserTimezone(okrById?.pending?.edges[0]?.node?.createdAt, 'DD MMM YYYY')}
                        </h4>

                      </Col>
                      {okrById?.isDiscarded?.id && <Col>
                        <div className='discard-small-head'>
                          Approved on
                        </div>
                        <h4 className='discard-small-data'>
                          {displayUtcToUserTimezone(okrById?.isDiscarded?.actedOn, 'DD MMM YYYY')}
                        </h4>

                      </Col>}
                      <Col>
                        <div className='discard-small-head'>
                          Rationale
                        </div>
                        <div className='discard-content' style={{ paddingTop: "10px" }}>
                          {okrById?.isDiscarded?.id && <div align='center'>{okrById?.isDiscarded?.initiatorsRational}</div>}
                          {okrById?.pending?.totalCount > 0 && <div align='center'>{okrById?.pending?.edges[0]?.node?.initiatorsRational}</div>}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

              {/* action section */}
              <Row align="middle" justify="center" style={{ padding: "30px 0px 30px 0px" }} wrap={true}>

                {
                  okrById?.pending?.totalCount > 0 && okrById?.discardRequestRaised.totalCount &&
                  <Button className="poppins" style={{
                    height: '45px', width: '170px', background: '#009AF1', color: '#FFFFFF',
                    fontSize: '16px', fontWeight: '500', borderRadius: '8px', margin: "5px"
                  }}
                    onClick={() => setDiscardCancelPopupVisible(true)}
                  >Cancel Request</Button>
                }
                {
                  (!okrDiscarded && checkRequestStatus()) && (
                    <div style={{ margin: "5px" }}>
                      <AddButton fontSize='16px' disabled={(okrDiscarded || !correctionDeadline)} onAdd={(e) => { setAddKr(true) }} label='Key Result' />
                    </div>
                  )
                }
                {
                  (!okrDiscarded && checkRequestStatus()) && (
                    <div style={{ margin: "5px" }}>
                      <AddWeightButtonContainer disabled={weightButtonDisabled || !correctionDeadline} {...props} okrId={okrById?.id}
                        tooltipTitle={"When you have multiple child key Results, by default the weightage will be equally divided amongst all of them. You can adjust the weight according to the value that you would like to assign for each child key Results"}
                      />
                    </div>
                  )
                }

                {
                  (entityHeadRoles?.isallowed_role && moment(okrById?.correctionDeadline).isAfter(moment()) && moment(okrById?.duedate).isAfter(moment())) ?
                    <div style={{ margin: "5px" }}>
                      <UpdateCorrectionDeadlineModal duedate={okrById?.duedate} startdate={okrById?.startdate} entityHeadRoles={entityHeadRoles} correctionDeadline={okrById?.correctionDeadline} {...props} />
                    </div>
                    : null
                }


              </Row>

              <div style={{ width: "100%", overflow: "auto" }}>
                <ObjectiveDetailTableView set_okr_tree={set_okr_tree} okrDiscarded={okrDiscarded} addChild={addKr} disabled={setWeightButtonDisabled} getWightList={() => getWightList()} removeAdd={(e) => { setAddKr(false) }} okrId={okrId} okrById={okrById} {...props} allKr={allKr} permission={permission} />
              </div>

            </div>
            <Col>
              <ChatBox okrId={okrById?.id} />
            </Col>
          </Row>
        )
      }
      <RequestDiscardView visible={discardPopupvisibleOBJ} {...props} discardData={okrById} onClose={() => { setDiscardPopupVisibleOBJ(false); setdiscardOKRDetail(null) }} discardedSuccess={(e) => { updateDiscardRow(e) }} />
      <RequestCancelDiscard visible={discardCancelPopupvisible} discardedSuccess={(e) => { updateDiscardRow(e) }} {...props} discardData={okrById} onClose={() => { setDiscardCancelPopupVisible(false) }} />
      <DeleteOkrDetails visible={deleteRecord} type="Objective" deletesuccess={(e) => navigateRoute("-1")} closeModal={() => { setDeleteRecord(null) }} okr={okrById?.id} {...props} />

    </>
  );
};

export default ObjectiveDetailView







export const AddButtonDiv = styled.div`
@media only screen and (max-width: 599px) {
  margin-top:20px;
}
`;

export const TableRow = styled(Row)`
@media only screen and (max-width: 1366px) {
  overflow:auto;
}
`;


export const StyledCol = styled(Col)`
margin-top:0px;
@media only screen and (max-width: 599px) {
  margin-top:20px;
}
`;

