import { compose, getItem } from 'modules/core';
import React, { useState } from 'react';
import ImpactNarrativeUI from '../components/ImpactNarrativeToolUI';
import { withApollo } from '@apollo/client/react/hoc';
import { Spin, message } from "antd";
import { diversity_survey_id, impact_generation_status, KNOWLEDGE_PART_1, PERSONALITY_PART_1, runpod_health_checktimer, runpod_warning_message, STRENGTH_PART_1, VALUE_SUERVEY_PART_1 } from '../../../config';
import { bodyRequiredValidator, getSurveyBase64StringFromInt, globalPermissionValidator, objectSanitizer } from 'modules/look';
import { GENERATE_AI_IMPACT_NARRATIVE, IMPACT_NARRATIVE_SUBSCRIPTION, LATEST_SURVEY_IMAPACT_NARRATIVE, RUNPOD_HEALTH_STATUS, USER_IMAPACT_NARRATIVE } from '../graphql/ImpactNarrative.gql';
import { withAddImpactNarrative, withUpdateImpactNarrative } from './ToolOperations';
import { impact_narrative_permission } from 'Permissions/ImpactNarrative.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import moment from 'moment';

const ImpactNarrativeContainer = props => {
  const { client, me, createImpactNarrative, updateImpactNarrative, userPermission } = props
  const [first_user_narrative, setFirstUserNarrative] = useState() // to store the impact narrative of the first employee
  const [second_user_narrative, setSecondUserNarrative] = useState() // to store the impact narrative of the second employee
  const [narrativeloading, setnarrativeloading] = useState(false) // to set the normal loader of the page
  const [generating_loader, setGeneratingLoader] = useState(false) // to set the loader while generating impact narrative
  const [user_narrative, setUserNarrative] = useState() // to store the current impact narrative of the logged in user
  const [permission, SetPermission] = React.useState(); // to store the user permission details
  const [survey_updation_date, SetSurveyUpdationDate] = React.useState()
  const [runpod_health,SetRunpodHealth] = React.useState() // to set the state of runpod worker status
  const user_narrative_ref = React.useRef()
  const impact_narrative_subscription_ref = React.useRef()
  const runpod_health_timer = React.useRef()
  const impact_narrative_generated =React.useRef(false)
  React.useEffect(()=>{
    if(user_narrative){
      user_narrative_ref.current = user_narrative
    }
  },[user_narrative])

  React.useEffect(()=>{
    if(survey_updation_date){
      if(survey_updation_date?.can_regenerate){
        if(runpod_health_timer?.current){
          clearInterval(runpod_health_timer?.current)
        }
        startRunpodHealthTimer()
        RunpodHealthCheck()
      }else{
        if(runpod_health_timer?.current){
          clearInterval(runpod_health_timer?.current)
        }
        SetRunpodHealth({
          workers:{idle:1}
        })
      }
    }
  },[survey_updation_date])

  const startRunpodHealthTimer=()=>{
    if(runpod_health_timer?.current){
      clearInterval(runpod_health_timer?.current)
    }
    runpod_health_timer.current = setInterval(() => {
      RunpodHealthCheck()
    }, (runpod_health_checktimer*1000));
    
  }

  React.useEffect(() => {
    return () => {
      if (impact_narrative_subscription_ref?.current) {
        impact_narrative_subscription_ref.current.unsubscribe();
      }
    }
  }, [])

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {

      if (page_load_handler.current) {
        event.preventDefault();
        // Chrome requires the returnValue to be set
        event.returnValue = 'Please stay on the page till impact narrative get generated';
        return event;
      }
    };

    // Attach the event listener to the window object
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      sessionStorage.removeItem(impact_generation_status)
      page_load_handler.current = false
      window.removeEventListener('beforeunload', () => { });
      if(runpod_health_timer?.current){
        clearInterval(runpod_health_timer?.current)
      }
    };
  }, []);

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(impact_narrative_permission, userPermission)
      SetPermission(permission)
      if (permission?.required_permission) {
        getCurrentUserImpactNarrative()
      }
    }

  }, [userPermission])

  let page_load_handler = React.useRef(false)
  React.useEffect(() => {
    page_load_handler.current = generating_loader
    if (impact_generation_status) {
      let data = { impact_generation_status: generating_loader ? "INPROGRESS" : "DONE" }
      sessionStorage.setItem(impact_generation_status, JSON.stringify(data))
    }
  }, [generating_loader])

  // to get the impact narrative of the current logged in user 
  const getCurrentUserImpactNarrative = async () => {
    try {
      setnarrativeloading(true)
      const { data } = await client.query({
        query: USER_IMAPACT_NARRATIVE,
        fetchPolicy: 'network-only',
        variables: { user: me?.id },
      });
      if (data?.allImpactNarrative?.edges?.length) {
        let impact_narrative_data = data?.allImpactNarrative?.edges[0]?.node
       
        let required_field = [
          "overview",
          "decisionMaking",
          "communication",
          "collaboration",
          "relationshipBuilding",
          "conflictManagement",
          "changeManagement",
          "learningStyle",
          "execution",
        ]
        let check_required_filed = bodyRequiredValidator(impact_narrative_data,required_field) 
        if(check_required_filed){
          changeGenerateBtnStatus(true)
          check_required_filed.missing.forEach(i=>impact_narrative_data[i]=null)
        }
        else{
          previousSurveyResponseDate(impact_narrative_data?.createdAt)
        }
        setUserNarrative(impact_narrative_data)
      } else {
        changeGenerateBtnStatus(true)
      }
      setnarrativeloading(false)
    } catch (error) {

    }
  }

  // to get the impact narrative of the current logged in user 
  const previousSurveyResponseDate = async (impact_date) => {
    try {
      const { data } = await client.query({
        query: LATEST_SURVEY_IMAPACT_NARRATIVE,
        fetchPolicy: 'network-only',
        variables: {
          user: me?.id,
          orderBy: ["responseDate"],
          last: 1,
          value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
          strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
          knowledge_skill: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
          personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
          diversity: getSurveyBase64StringFromInt(diversity_survey_id)
        },
      });
      if (data) {

        let response_date = Object.values(data)?.map(i => {
          if (i?.edges?.length) {
            return i?.edges[0]?.node?.responseDate
          } else {
            return null
          }
        })?.filter(i => i)?.sort((a, b) => moment(b)?.unix() - moment(a)?.unix())

        if (!response_date?.length) {
          changeGenerateBtnStatus(true)
        } else if (moment(response_date[0])?.isSameOrAfter(impact_date)) {
          changeGenerateBtnStatus(true)
        } else {
          changeGenerateBtnStatus(false)
        }
      }
    } catch (error) {

    }
  }

  const changeGenerateBtnStatus = (value) => {
    SetSurveyUpdationDate({
      can_regenerate: value
    })
  }

  // to get the impact narrative of the other employees of organization
  const getuserImpactNarrative = async (id, type) => {
    try {
      setnarrativeloading(true)
      const { data } = await client.query({
        query: USER_IMAPACT_NARRATIVE,
        fetchPolicy: 'network-only',
        variables: { user: id },
      });
      if (data?.allImpactNarrative?.edges?.length) {
        let required_field = [
          "overview",
          "decisionMaking",
          "communication",
          "collaboration",
          "relationshipBuilding",
          "conflictManagement",
          "changeManagement",
          "learningStyle",
          "execution",
        ]
        let detail = data?.allImpactNarrative?.edges[0]?.node
        let check_required_filed = bodyRequiredValidator(detail,required_field) 
        if(check_required_filed){ 
          check_required_filed.missing.forEach(i=>detail[i]=null)
        }
       
        if (type === 'first') {
          setFirstUserNarrative(detail)
        } else {
          setSecondUserNarrative(detail)
        }
        setnarrativeloading(false)
      } else {
        if (type === 'first') {
          setFirstUserNarrative(null)
        }
        else {
          setSecondUserNarrative(null)
        }
        setnarrativeloading(false)
        message.error('User has not submitted impact narrative')
      }
    }
    catch (err) {
      if (type === 'first') {
        setFirstUserNarrative(null)
      }
      else {
        setSecondUserNarrative(null)
      }
      setnarrativeloading(false)
      message.error('Cannot perform this action')
    }
  }

  const cleanup = (type) => {
    if (type === "first") {
      setFirstUserNarrative(null)
    } else {
      setSecondUserNarrative(null)
    }
  }

  const generateImpactNarrative = async () => {
    try {
      setGeneratingLoader(true)
      let runpod_status = await RunpodHealthCheck()
      if(runpod_status?.workers?.idle<=0){
        message.error(runpod_warning_message)
        setGeneratingLoader(false)
        return
      }
      if(runpod_health_timer?.current){
        clearInterval(runpod_health_timer?.current)
      }
      if (impact_narrative_subscription_ref?.current) {
        impact_narrative_subscription_ref.current.unsubscribe();
        impact_narrative_subscription_ref.current = null;
      }
      subscribeToImpactNarrative()
      await client.query({
        query: GENERATE_AI_IMPACT_NARRATIVE,
        fetchPolicy: "network-only",
      });

    } catch (error) {
      StopLoader()
      message.error("Failed to generate Impact Narrative")
    }
  }

  const RunpodHealthCheck = async () => { 
    let default_data = {
      workers:{idle:1}
    }
    try { 
      
      const { data } = await client.query({
        query: RUNPOD_HEALTH_STATUS,
        fetchPolicy: 'network-only',
        variables: { eptype: "impact_narrative" },
      }); 
      if(data?.getEndpointHealth){
        let runpod_data = JSON.parse(data?.getEndpointHealth) 
        SetRunpodHealth(runpod_data)
        return runpod_data
      }else{
        SetRunpodHealth(default_data)
        return default_data
      }
    }
    catch(e){
      SetRunpodHealth(default_data)
      return default_data
    }
  }

  const subscribeToImpactNarrative = () => {
    try {

      impact_narrative_subscription_ref.current = client.subscribe({
        query: IMPACT_NARRATIVE_SUBSCRIPTION,
      }).subscribe({
        next(result) {
          let data_from_sub = result.data.impactNarrativeSubscription?.impactNarrative 
          switch (result.data.impactNarrativeSubscription.mutation) {

            case "CREATE":
              setUserNarrative(data_from_sub)
              changeGenerateBtnStatus(false)
              impact_narrative_generated.current=true
              StopLoader()
              break
            default:
              break
          }
        }
      })
    } catch (error) {
      StopLoader()
    }
    setTimeout(()=>{
      StopLoader()
    },240000)
  }

  React.useEffect(() => {
    return () => {
      if (impact_narrative_generated.current) {
        localStorage.setItem('review_module','Soul')
        impact_narrative_generated.current=false
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const StopLoader = () => {
    setGeneratingLoader(false)
    setnarrativeloading(false)
    if (impact_narrative_subscription_ref?.current) {
      impact_narrative_subscription_ref.current.unsubscribe();
      impact_narrative_subscription_ref.current = null;
    }
  }

 
  const OnUpdateAction = async (data) => {
    try {
      setnarrativeloading(true)
      let response = await updateImpactNarrative(data)
      if (response?.id) {
        let values = { ...user_narrative_ref.current, ...data }
        setUserNarrative(values)
        message.success("Impact narrative update successfully")
      }
      setnarrativeloading(false)
    } catch (error) {
      setnarrativeloading(false)
    }
  }


  return (
    <>
      {
        (permission && permission?.required_permission) && (
          <ImpactNarrativeUI
            getuserImpactNarrative={(id, type) => { getuserImpactNarrative(id, type) }}
            first_user_narrative={first_user_narrative}
            second_user_narrative={second_user_narrative}
            data_clean_up={(type) => { cleanup(type) }}
            user_narrative={user_narrative}
            narrativeloading={narrativeloading}
            generateImpactNarrative={generateImpactNarrative}
            generating_loader={generating_loader} 
            OnUpdateAction={OnUpdateAction}
            permission={permission}
            survey_updation_date={survey_updation_date}
            runpod_health={runpod_health}
            {...props} />
        )
      }

      {
        (permission && !permission?.required_permission) && (
          <NoPermissionView />
        )
      }

      {
        !permission && (
          <div style={{ paddingTop: "150px" }} align="center">
            <Spin spinning="true"></Spin>
          </div>
        )
      }
    </>

  );
};

export default compose(withApollo, withAddImpactNarrative, withUpdateImpactNarrative)(ImpactNarrativeContainer);