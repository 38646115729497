import React from "react";
import { Row, Col, Button, Card, Tooltip, Menu, Spin, Input, Form, Table, Dropdown, Select, Popover, Modal, Switch, InputNumber } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString, scrolltoview, DatePickerField, TextField } from "../../look";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from 'moment-timezone';
import AddAgendaContainer from '../containers/AddAgendaContainer'
import MultiSelectEmployee from './MultiSelectEmployee'
import { GOAL_HOME_TOOL_ID, ONEONONE_HOME_TOOL_ID } from '../../../config'
import { useHistory } from 'react-router-dom';
import OccuranceView from "./OccuranceModal";
import "../oneONone.css"
import Home_soul from '../../assets/home-icon-soul.svg'
import Right_arow from '../../assets/right-arrow-soul.svg'
import Meeting_title from '../../assets/meeting-title-icon.svg'
import okr from '../../assets/okr-redirection.svg'
import delete_icon from '../../assets/delete-agenda.svg'
import bookmark_link from '../../assets/bookmark-link.svg'
import three_dot from '../../assets/three-dot.svg'
import Back_icon from '../../assets/soul-back.svg'
import calender_icon from '../../assets/meeting-calender-icon.svg'
import time_icon from '../../assets/time-icon.svg'
import recurence_icon from '../../assets/reccurence-icon.svg'
import employee_icon from '../../assets/employee-profile-icon.svg'
import CustomTimePicker from "modules/look-v2/Components/custom-time-picker";
import one_on_one_route from '../route/index'
import MeetingAiTipContainer from "modules/ai-tips/container/meeting-ai-tip-container";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
// import moment from 'moment';
const AddOneonOneView = props => {
  const history = useHistory();
  const { me, createMeeting, createActionItem, allEmpoloyee, meetingById, location, searchKey,
    allEmpoloyeeResult, meetingTimeSlotSet,app_sidebar_expand,navigateRoute,SetAITrigger,ai_trigger,permission } = props
  const [loading, setloading] = React.useState(false);
  const [form] = Form.useForm();
  const [memberlist, setmemberlist] = React.useState([]);
  const [title, setTitle] = React.useState();
  const [when, setwhen] = React.useState();
  const [selected_time, setSelectedTime] = React.useState()
  const [end_time, setend_time] = React.useState();
  const [addAgenda, setaddAgenda] = React.useState(false);
  const [titleInvalid, settitleInvalid] = React.useState(false);
  const [memberListInvalid, setmemberListInvalid] = React.useState(false);
  const [dateInvalid, setdateInvalid] = React.useState(false);
  const [employeeList, setemployeeList] = React.useState([]);
  const [agendaList, setagendaList] = React.useState([]);
  const [info, setinfo] = React.useState()
  const [eventValue, setEventValue] = React.useState()
  const [visible, setVisible] = React.useState(false)
  const [durationInvalid, setdurationInvalid] = React.useState(false);
  const [enableSeries, setEnableSeries] = React.useState(false)
  const [occurance, set_occurance] = React.useState({
    label: "Does not Repeat",
    type: "does_not_repeat"
  })
  const [agendaEnable, setEnable] = React.useState(true)
  const [occurance_modal, setoccurance_modal] = React.useState(false)
  const [rrule, setrrule] = React.useState()
  const [selectedItem, setSelectedItem] = React.useState()
  const [selectedDateTime, setSelectedDateTime] = React.useState(undefined);
  const [selected_end_date, setSelectedEndDate] = React.useState()
  const ref = React.useRef(null);
  const [selectedCustomTime, setSelectedCustomTime] = React.useState(null);
  const [newMeetingMessage, setNewMeetingMessgae] = React.useState([]);

  const { Option } = Select;
  const FormItem = Form.Item;
  const back = () => {
    navigateRoute("-1")
    // history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))

  };
  const now = moment()

  React.useEffect(() => {
    if (allEmpoloyee && me && meetingById == undefined) {
      let list
      let teamMembersEmployee = [].concat.apply(
        [], allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({ node }) => node?.managers?.edges.map(({ node }) => node?.user)))
      let teamManager = [].concat.apply(
        [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.vertical?.heads?.edges.map(({ node }) => node?.user)))
      let teamManagerEmployee = [].concat.apply(
        [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.employees?.edges.map(({ node }) => node?.user)))
      let orgPocEmployee = [].concat.apply(
        [], allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
      let orgCeoEmployee = [].concat.apply(
        [], allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
        let orgVerticalEmployee=[].concat.apply(
          [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
          // let verticalMembersEmployee=[].concat.apply(
          //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
            let verticalHeadEmployee=[].concat.apply(
              [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
          list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
      let data = list.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.firstName === value.firstName && t.id === value.id
        )))
      let filtered = data.filter(item => item?.employee?.id !== me?.employee?.id)
      setemployeeList(filtered)
    }
  }, [allEmpoloyee, me,])


  React.useEffect(() => {
    let lastFilter
    let firstFilter
    if (allEmpoloyeeResult?.first) {
      let allEmpoloyee = allEmpoloyeeResult?.first
      if (allEmpoloyee) {
        let list
        let teamMembersEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({ node }) => node?.managers?.edges.map(({ node }) => node?.user)))
        let teamManager = [].concat.apply(
          [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.vertical?.heads?.edges.map(({ node }) => node?.user)))
        let teamManagerEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.employees?.edges.map(({ node }) => node?.user)))
        let orgPocEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
        let orgCeoEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
          let orgVerticalEmployee=[].concat.apply(
            [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
            // let verticalMembersEmployee=[].concat.apply(
            //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let verticalHeadEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
            list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
        let data = list.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.firstName === value.firstName && t.id === value.id
          )))
        firstFilter = data.filter(item => item?.employee?.id !== me?.employee?.id)
      }
    }
    if (allEmpoloyeeResult?.last) {
      let allEmpoloyee = allEmpoloyeeResult?.last
      if (allEmpoloyee) {
        let list
        let teamMembersEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({ node }) => node?.managers?.edges.map(({ node }) => node?.user)))
        let teamManager = [].concat.apply(
          [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.vertical?.heads?.edges.map(({ node }) => node?.user)))
        let teamManagerEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({ node }) => node?.employees?.edges.map(({ node }) => node?.user)))
        let orgPocEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
        let orgCeoEmployee = [].concat.apply(
          [], allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({ node }) => node?.employees.edges.map(({ node }) => node.user)))
          let orgVerticalEmployee=[].concat.apply(
            [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
            // let verticalMembersEmployee=[].concat.apply(
            //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let verticalHeadEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
            list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
        let data = list.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.firstName === value.firstName && t.id === value.id
          )))
        lastFilter = data.filter(item => item?.employee?.id !== me?.employee?.id)
      }
      let user = firstFilter.concat(lastFilter)
      var setObj = new Set(); // create key value pair from array of array
      var result = user.reduce((acc, item) => {
        if (!setObj.has(item?.employee?.id)) {
          setObj.add(item?.employee?.id)
          acc.push(item)
        }
        return acc;
      }, [])
      setemployeeList(result)
    }
  }, [allEmpoloyeeResult])
  const Capitalize = (value) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';
  React.useEffect(() => {

    if (meetingById) {
      let member
      let memberlist = meetingById?.member?.edges?.map(({ node }) => node.user)
      if (memberlist[0]?.firstName === me?.firstName) {
        member = [memberlist[1]]
        setTitle(`${Capitalize(me?.firstName)} and ${Capitalize(memberlist[1]?.firstName)}'s 1:1`)
        setEnable(false)
      }
      else {
        member = memberlist
        setEnable(false)
        setTitle(meetingById?.title)
      }
      setmemberlist(member)
      let ids = member?.map(item => item?.employee?.id)
      let list = agendaList.filter(data => ids?.includes(data?.member?.employee?.id))
      setagendaList(list)
    }
  }, [meetingById, me])




  const anchorStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    marginTop: '5px'
  }

  const columns = [
    {
      title: "Title",
      key: 'Title',
      render: (text, record) => record &&
        <Tooltip title={record?.title?.length > 16 ? record?.title : ''} placement="right">
          <p style={anchorStyle}>{record?.title}</p>
        </Tooltip>
    },
    {
      title: "relatedTo",
      key: 'relatedTo',
      render: (text, record) => record &&
        <p >{relate(record)}</p>
    },
    {
      title: '',
      key: '',
      render: (text, record) => record &&
        <div align="end" >
          <Button shape="circle" type="danger" onClick={() => RemoveAgenda(record)}>
            <DeleteOutlined />
          </Button>
        </div>

    }
  ];

  const RemoveAgenda = (record) => {
    let list = [].concat(agendaList)
    let index = list.indexOf(record)
    list.splice(index, 1)
    setagendaList(list)
  }

  const relate = (data) => {
    if (data?.okrId) {
      return data?.okrId?.title
    }
    else if (data?.commitmentId) {
      return data?.commitmentId?.title
    }
    else if (data?.coachingCommitmentId) {
      return data?.coachingCommitmentId?.title
    }
  }
  const ai_tip__meeting_triggered_ref =React.useRef(false)
  const handleMenuClick = (members) => {
    if (members) {
      setmemberListInvalid(false)
      setEnable(false)
      setTitle()
      setwhen()
      setagendaList([])
      setSelectedCustomTime()
      set_occurance({
        label: "Does not Repeat",
        type: "does_not_repeat"
      })
      setEnableSeries(false)
      // if(!ai_tip__meeting_triggered_ref.current){
      //   let trigger = {trigger:ai_trigger_content['one_on_one_meeting_schedule']}
      //   SetAITrigger({...trigger})
      //   ai_tip__meeting_triggered_ref.current=true
      // } 
    }
    else {
      setEnable(true)
    }
    setmemberlist(members)
    let ids = members?.map(item => item?.employee?.id)
    let list = agendaList.filter(data => ids?.includes(data?.member?.employee?.id))
    setagendaList(list)
    if (members?.length) {
      setTitle(`${Capitalize(members[0]?.firstName)} and ${Capitalize(me?.firstName)}'s 1:1`)
      settitleInvalid(false)
    }
  }

  const ValidDays = (current) => {
    let customDate = new Date();
    return current < moment(customDate, 'YYYY-MM-DD HH:mm');
  }

  const SubmitAgenda = (data) => {
    let list = [].concat(agendaList)
    list.push(data)
    setagendaList(list)
    setaddAgenda(false)
  }

  const handleSubmit = async () => {
    let values = form.getFieldsValue()
    if (title && memberlist?.length && when && values?.duration > 0) {
      let selected_date_time
      if (when && selected_time) {

        let date = moment(when).format("YYYY-MM-DD")
        const roundedTime = moment({
          hour: selected_time.hour(),
          minute: Math.ceil(selected_time.minute() / 15) * 15,
        }).format("HH:mm");
        selected_date_time = `${date} ${roundedTime}`

      }
      else if (!selected_time) {
        if (moment(when._d).minute() < 45) {
          let date = moment(when).format("YYYY-MM-DD")
          let roundedTime = moment({
            hour: moment(when._d).hour(),
            minute: Math.ceil(moment(when._d).minute() / 15) * 15,
          }).format("HH:mm");
          selected_date_time = `${date} ${roundedTime}`
        }
        else {
          let date = moment(when).format("YYYY-MM-DD")
          let time_diff = 60 - Math.ceil(moment(when._d).minute())
          let new_time = moment(when._d).add(time_diff, 'minutes')
          let roundedTime = moment({
            hour: moment(new_time).hour(),
            minute: Math.ceil(moment(new_time).minute() / 15) * 15,
          }).format("HH:mm");
          selected_date_time = `${date} ${roundedTime}`
        }
      }
      setloading(true)
      settitleInvalid(false)
      setmemberListInvalid(false)
      let previous = new URLSearchParams(props.location.search).get("previous")
      let meetingData = {}
      if (previous) {
        meetingData = {
          title: title,
          meetingType: "One-On-One",
          previousMeetingId: previous,
          when: moment(selected_date_time).utc().toISOString(),
          // rrule:occurance?.type!=='does_not_repeat'?rrule:null,
          scheduleDuration: values?.duration * 60
        }
      }
      else {
        meetingData = {
          title: title,
          meetingType: "One-On-One",
          when: moment(selected_date_time).utc().toISOString(),
          // rrule:occurance?.type!=='does_not_repeat'?rrule:null,
          scheduleDuration: values?.duration * 60
        }
      }
      if (occurance?.type !== 'does_not_repeat') {
        meetingData['rrule'] = rrule
      }
      let members = memberlist.map(user => getIntFromString(user?.employee?.id))
      members.push(getIntFromString(me?.employee?.id))
      const response = await createMeeting({ members: members, meetingData: meetingData });
      if (response && response?.id) {
        if (agendaList.length) {
          let meetingid = getIntFromString(response?.id)

          for (const item of agendaList) {
            let actionItemData = {
              title: item?.title,
              meetingId: meetingid,
              okrId: item?.okrId?.id ? getIntFromString(item?.okrId?.id) : null,
              commitmentId: item?.commitmentId?.id ? getIntFromString(item?.commitmentId?.id) : null,
              coachingCommitmentId: item?.coachingCommitmentId?.id ? getIntFromString(item?.coachingCommitmentId?.id) : null,
              notes: item?.notes,
              isAgenda: item?.isAgenda,
              employeeId: item?.member?.employee?.id?getIntFromString(item?.member?.employee?.id):getIntFromString(me?.employee?.id)
            }
            const agenda = await createActionItem({ actionItemData });
          }
          setloading(false)
          if (location?.search) {
            history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
          }
          else {
            history.goBack()
          }
        }
        else {
          setloading(false)
          if (location?.search) {
            history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
          }
          else {
            history.goBack()
          }
        }

      } else {
        setloading(false)
      }
    }
    else {
      if (!title) {
        settitleInvalid(true)
        ref.current.focus();
      }
      else {
        settitleInvalid(false)
      }
      if (!memberlist?.length) {
        setmemberListInvalid(true)
        ref.current.focus();
      }
      else {
        setmemberListInvalid(false)
      }
      if (!when) {
        setdateInvalid(true)
        ref.current.focus();
      }
      else {
        setdateInvalid(false)
      }
      if (values?.duration <= 0) {
        setdurationInvalid(true)
      }
      else {
        setdurationInvalid(false)
      }
    }
  }

  const disabledHours = () => {
    const hours = [];
    if (when && moment(when).isAfter(moment())) {
      return hours;
    }
    else {
      for (let i = 0; i < moment().hour(); i += 1) hours.push(i);
      return hours;
    }
  };

  function handleChangeToUtc(val) {
    //Vals to be submitted to backend must be in utc
    if (typeof (props.onChange) === 'function') {
      props.onChange(val ? val?.utc() : val)

    }
  }
  const disabledMinutes = (selectedHour) => {


    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;

  };

  const handleTitleSubmit = (e) => {
    let title = e.target.value
    if (title.length >= 200) {
      setVisible(true)
      settitleInvalid(true)
    }
    else {
      setVisible(false)
      settitleInvalid(false)
    }
    setTitle(e.target.value)
  }
  const hide = () => {
    setVisible(false)
  }

  const handleChange = (value) => {

    if (value) {
      setwhen(value)
      setdateInvalid(false)
    }
    else {
      setwhen(null)
    }

  }

  const searchInput = (value) => {
    searchKey(value)
  }

  const occurance_menu_list = [
    {
      label: "Does not Repeat",
      type: "does_not_repeat"
    },
    {
      label: "Every weekday(Mon-Fri)",
      type: "Week",
      days: ['MO', 'TU', 'WE', 'TH', 'FR']
    },
    {
      label: "Daily",
      type: "Day"
    },
    {
      label: "Weekly",
      type: "Week",
    },
    {
      label: "Monthly",
      type: "Month"
    },
    {
      label: "Custom",
      type: "Day"
    }
  ]

  const changeOccurance = (value) => {
    set_occurance(value)
    if (value?.type !== 'does_not_repeat') {
      setoccurance_modal(true)
    }

  }

  const occurance_list = () => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }} >

      {occurance_menu_list?.map((data, index) =>
        <Menu.Item className="one-on-one-input-field-style" key={index} onClick={() => { setinfo(null); setrrule(null); changeOccurance(data) }}>
          {data?.label}
        </Menu.Item>
      )}
    </Menu>
  );
  const changeRule = (e) => {

    setrrule(e?.rrule)
    setinfo(e?.info)
    setwhen(e?.start_date)
    setSelectedEndDate(e?.end_date)
    setoccurance_modal(false)
  }

  const goHome = () => {
    history.push('/')
  }

  const redirectToOkr = (okr) => {
    if (okr?.okrType === 'OBJECTIVE') {
      window.open(`/role/objective-keyresult/view/${getIntFromString(okr?.id)}`, "_blank");

    }
    else if (okr?.okrType === 'KEY_RESULT') {
      window.open(`/role/objective-keyresult/keyresult/view/${getIntFromString(okr?.id)}`, "_blank");
     
    }
    else if (okr?.okrType === 'MILESTONE') {
      window.open(`/role/objective-keyresult/milestone/view/${getIntFromString(okr?.id)}`, "_blank");
    }
  }

  const onChange = (checked) => {
      if (!when) {
        setdateInvalid(true)
      }
      else{
        setEnableSeries(checked)
      }
      
  };
  const goGoal = () => {
    history.push(`/page/goal/home/${GOAL_HOME_TOOL_ID}`)
  }
  const roleGoal = () => {
    history.push(`/page/role_goal/home/${GOAL_HOME_TOOL_ID}`)
  }

  const EditActionItem = (item) => {
    setSelectedItem(item)
    setaddAgenda(true)
  }

  const roundTime = (time) => {
    let timeValue
    if (moment(time).minute() < 45) {
      timeValue = moment({
        hour: moment(time).hour(),
        minute: Math.ceil(moment(time).minute() / 15) * 15,
      })
    }
    else {
      let time_diff = 60 - Math.ceil(moment(time).minute())
      let new_time = moment(time).add(time_diff, 'minutes')
      timeValue = moment({
        hour: moment(new_time).hour(),
        minute: Math.ceil(moment(new_time).minute() / 15) * 15,
      })
    }
    return timeValue
  }

  const getDateFormatted = (time) => {
    let date = moment(when).format("YYYY-MM-DD")
    if (time) {
      return `${date} ${moment(time, ['h:mm A']).format('HH:mm')}`
    }
  }

  const handleTimeChange = (time) => {

    let selected_date_time;
    selected_date_time = getDateFormatted(time)
    setSelectedTime(moment(selected_date_time))
    setSelectedDateTime(selected_date_time)
    setSelectedCustomTime(time);

  };

  const topFunction = () => {
    window.scrollTo(0, 300);
    // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  React.useEffect(() => {

    if (when) {

      let formattedDatetime = getDateFormatted(roundTime(when?._d))
      setSelectedDateTime(formattedDatetime)


    }

  }, [when])


  const getStatusOfMeetingTimings = (utcFormattedSelectedDateTime) => {
    let messageArray = [];
    let allMeetingtimingsCheck = meetingTimeSlotSet?.map((slot) =>

      (moment(utcFormattedSelectedDateTime).isBetween(moment(slot[0]), moment(slot[1]))) ?
        messageArray.push("IN_BETWEEN_TIME")

        : (moment(utcFormattedSelectedDateTime).isSame(moment(slot[0]))) ?

          messageArray.push("IDENTICAL_START_TIME")
          :
          moment(utcFormattedSelectedDateTime).isSame(moment(slot[1])) ?
            messageArray.push("IDENTICAL_END_TIME")

            : setNewMeetingMessgae([])
    )


    return Array.from(new Set(messageArray));
    ;

  }

  // make an array for start and end time of meeting and check it with current selected date and time

  React.useEffect(() => {

    if (selectedDateTime) {

      let utcFormattedSelectedDateTime = moment(selectedDateTime).toISOString();

      if (meetingTimeSlotSet?.length) {
        let statusOfTimings = getStatusOfMeetingTimings(utcFormattedSelectedDateTime)
        setNewMeetingMessgae(statusOfTimings)
      }
    }

  }, [selectedDateTime])




  const messageForConflictingMeeting = () => {

    return (

      <div>
        <Row justify="end" style={{marginBottom:'2px'}}><a onClick={(e)=>setNewMeetingMessgae([])} >Close</a></Row>
          <div>
        {
          newMeetingMessage?.length &&
            newMeetingMessage?.includes("IDENTICAL_END_TIME") ?
            <div>{"Hey! You are booking yourself back to back? Don't want a breather?"}</div>
            :
            newMeetingMessage?.includes("IN_BETWEEN_TIME") ?
              <div>{"Hey! You seem to already have a meeting at this time. Do you really want to double-book yourself?"}</div>
              :

              newMeetingMessage?.includes("IDENTICAL_START_TIME") ?
                <div>{"Hey! You seem to already have a meeting at this time. Do you really want to double-book yourself?"}</div>

                :
                null
        }

      </div>

      </div>
    )

  }

  let previous_add_agenda_trigger_data = React.useRef({ title: "" })
  const AiTip =(data)=>{
    let title = data?.value?.trim()
    if(title && previous_add_agenda_trigger_data.current.title != title && title?.trim()!==""){
      previous_add_agenda_trigger_data.current = {title};
      if(SetAITrigger){
        let trigger = {
          trigger:ai_trigger_content[data?.key],
          dynamic_data :[title],
          optype:"CREATE" 
        }
        SetAITrigger({...trigger})
      }
    } 
  }

  return (
    <>
      <MetaTags
        title="Add 1:1"
        description="This is Create 1:1 page."
      />
      <Modal visible={occurance_modal} className='occurance-modal' centered={true} destroyOnClose={true} footer={false} closable={false}>
        <OccuranceView when={when} type={occurance} onClose={() => { setoccurance_modal(false); set_occurance({ label: "Does not Repeat", type: "does_not_repeat" }) }} changeRule={(e) => changeRule(e)} selected_end_date={selected_end_date} />
      </Modal>
      <Spin spinning={loading}>
        <div style={{ padding: '0px 2px', position: 'relative', top: '30px' }}>
          <Row justify="center" align="center" style={{ display: 'flex', flexDirection: 'column', gap: "10px", position: 'relative', marginTop: "10px", marginBottom: "15px" }} className="one-on-one-heading-seaction ">
            <img className="back-icon-one-on-one back-arrow-global" style={{ cursor: "pointer" }} src={Back_icon} alt="" onClick={() => back()} />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
              <h2 className="one-on-one-head" style={{ marignBottom: '0px' }}>SCHEDULE 1 : 1</h2>
            </div>
          </Row>
          <Form
            form={form}
            name={"1:1"}
            initialValues={{ duration: 1 }}
            onFinish={() => { handleSubmit() }}
          >

            <Row gutter={5} justify="center" align="center" style={{ marginBottom: "120px" }}>

              <Col className="schedule-one-on-one-container" lg={{ span: 13 }} md={{ span: 16 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{
                background: " #F5F5F5",
                border: " 1px solid #D9D9D9", borderRadius: "8px"
              }}>
                <Row gutter={10}>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: '15px' }}>

                    <p className="one-on-one-form-title">Participant</p>
                    {meetingById != undefined ?
                      <div style={{ position: "relative" }}>
                        <Input
                          style={{
                            border: !titleInvalid ? '1px solid #D9D9D9' : '1px solid #ff4d4f',
                            borderRadius: "8px",
                            height: "40px",
                            paddingLeft: "42px"
                          }}
                          value={memberlist[0]?.firstName}
                          onChange={(e) => (setTitle(e.target.value))}
                          id="select-employee"
                          onClick={(e) => { scrolltoview('select-employee') }}
                          readOnly={true}
                          autoComplete='off'
                        />
                        <img className='instant-form-icon' src={employee_icon} alt="" style={{ position: "absolute", left: "10px" }} />
                      </div> :
                      <MultiSelectEmployee
                        ref={ref}
                        memberListInvalid={memberListInvalid}
                        dataList={employeeList}
                        onChange={(e) => { handleMenuClick(e) }}
                        searchKey={e => searchInput(e)}
                      />
                    }
                    {
                      memberListInvalid && (
                        <p style={{ color: 'red', marginTop: '10px' }}>This field is required.</p>
                      )
                    }

                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: '24px' }}>
                    <p className="one-on-one-form-title">Title</p>

                    <Popover
                      content={<a onClick={hide}>Close</a>}
                      title="Character Limit Exceeded"
                      trigger="click"
                      visible={visible}
                      placement="topRight"
                    >
                      <div style={{ position: "relative" }}>
                        <Input
                          // disabled={memberlist?.length?false:true}
                          style={{
                            border: !titleInvalid ? '1px solid #D9D9D9' : '1px solid #ff4d4f',
                            borderRadius: "8px",
                            height: "40px",
                            paddingLeft: "42px"
                          }}
                          id="1_1_title_name"
                          className="one-on-one-input-field-style"
                          value={title}
                          maxLength={200}
                          minLength={1}
                          // onChange={(e) => (setTitle(e.target.value))}
                          onChange={(e) => handleTitleSubmit(e)}
                          onClick={(e) => { scrolltoview('1_1_title_name') }}
                          ref={ref}
                          placeholder="Title of Your 1:1"
                          autoComplete='off'
                        />
                        <img className='instant-form-icon' src={Meeting_title} alt="" style={{ position: "absolute", left: "10px" }} />
                      </div>
                    </Popover>
                    {
                      titleInvalid && (
                        <p style={{ color: 'red', marginTop: '10px' }}>This field is required.</p>
                      )
                    }
                  </Col>
                </Row>
                <Row justify="start" align="start" style={{ gap: "30px" }} className="meeting-create-selction">
                  <Col style={{ marginTop: '24px' }}>
                    <p className="one-on-one-form-title"> When</p>
                    <Row style={{ gap: "10px" }} onClick={() => topFunction()}>
                      <Popover
                      overlayClassName="datepicker-new-meeting-container" 
                      style={{zIndex:'100'}}
                        visible={newMeetingMessage?.length ? true : false}
                        content={messageForConflictingMeeting()}>
                        <div>
                          <DatePickerField
                            ref={ref}
                            suffixIcon={<img src={calender_icon} alt="" />}
                            style={{
                              width: '100%',
                              maxWidth: "200px",
                              borderRadius: "8px",
                              height: "40px",
                              border: !dateInvalid ? '' : '1px solid #ff4d4f'
                            }}
                            // className="one-on-one-input-field-style"
                            format="YYYY-MM-DD"
                            placeholder='Select Date'
                            onChange={e => { (e ? setwhen(e) : setwhen(null)); setdateInvalid(false) }}
                            id="1_1_when"
                            onClick={(e) => { scrolltoview('1_1_when') }}
                            disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                            allowClear={false}
                            value={when ? moment(when?._d) : ''}
                          />
                          {
                            dateInvalid && (
                              <p style={{ color: 'red', marginTop: '-10px' }}>This field is required.</p>
                            )
                          }
                        </div>
                      </Popover>
                      <div>


                        <CustomTimePicker selectedTime={selectedCustomTime} onedit={false} disabled={when ? false : true} when={when} onChange={handleTimeChange} />
                      </div>

                    </Row>
                  </Col>
                  <Col style={{ marginTop: '24px' }} className="oc-duration">
                    <p className="one-on-one-form-title">Duration</p>

                    <div className={durationInvalid ? 'invalid-duration' : ''} style={{ position: "relative" }}>
                      <TextField type='number' className="one-on-one-input-field-style" placeholder='For how long' margin="0px" widthscale="100px" name="duration" style={{ paddingRight: "20px" }} />
                      {/* <InputNumber type='number'min={0} max={10} className="one-on-one-input-field-style" placeholder='For how long' margin="0px" widthscale="100px" name="duration" style={{ paddingRight: "20px" }} /> */}
                      <img src={time_icon} alt="" style={{ position: "absolute", right: "10px", top: "10px" }} />
                    </div>
                  </Col>
                  <Col style={{ marginTop: '24px' }} className="oc-duration">
                    <p className="one-on-one-form-title">Series</p>

                    <Switch style={{ marginTop: "6px" }} checked={enableSeries} onChange={(e)=>onChange(e)} />
                  </Col>
                </Row>

                {enableSeries && <Col lg={{ span: 12 }} md={{ span: 15 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: '24px' }}>
                  <p className="one-on-one-form-title">Recurrence</p>
                  <Tooltip title={info || occurance?.label}>
                    <div style={{ position: "relative" }}>

                      <Dropdown disabled={!when} overlay={occurance_list} style={{ cursor: 'pointer' }} trigger="click" >
                        <DropdownButton
                          style={{
                            border: '1px solid #D9D9D9',
                            borderRadius: "8px",
                            height: "40px",
                            background: "#FFF",
                            paddingTop: "20px"
                          }}>
                          <div>

                          </div>
                          <p className="one-on-one-input-field-style" style={{ paddingLeft: "24px", textOverflow: "elipsis", overflow: "hidden" }}  >{info || occurance?.label}</p>
                          <DownOutlined style={{ color: "#BFBFBF", marginTop: "-10px" }} />
                        </DropdownButton>
                        {/* </Tooltip> */}
                      </Dropdown>
                      <img src={recurence_icon} alt="" style={{ position: "absolute", left: "10px", top: "10px" }} />
                    </div>
                  </Tooltip>
                </Col>}


                <Col span={24} style={{ marginTop: '20px', background: "#FFF", padding: "10px", borderRadius: '8px' }} >

                  <div align='center' style={{ lineHeight: '35px', fontWeight: '700', fontFamily: 'poppins', marginTop: '10px', fontSize: '16px' }}>AGENDA</div>
                  <div className="agenda-create-overflow" style={{ background: "#FFF", width: "100%", padding: "20px 10px", minHeight: "300px", maxHeight: "400px", overflow: "auto" }}>
                    {!addAgenda && <Button className="one-on-one-meeting-items-add-btn" disabled={agendaEnable} onClick={() => {setaddAgenda(true)}}>
                      + New Agenda
                    </Button>}
                    {addAgenda &&
                      <Spin spinning={loading}>
                        <AddAgendaContainer
                          {...props}
                          memberlist={memberlist}
                          header={"Add Agenda"}
                          onSubmit={(e) => { SubmitAgenda(e) }}
                          me={me}
                          close={() => { setaddAgenda(false) }}
                          agenda={selectedItem}
                          isAgenda={true}
                          customCss={true}
                          AiTip={(key)=>AiTip(key)}
                        />
                      </Spin>}
                    {!addAgenda &&
                      <>
                        {agendaList?.length ? agendaList?.map(item => (

                          <div className="agend-list-div" style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                            <h4 className="one-on-one-agenda-title" style={{ textAlign: "left" }}>{item?.title}</h4>
                            <div className="popover-container" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "nowrap", gap: "10px" }}>
                              {item?.okrId?.id && <Popover placement="bottomRight" content={<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: "10px 20px", maxWidth: "300px" }}>
                                <h4 className="current-one-on-one-meeting-head" >{`Related to ${item?.okrId?.owner?.user?.firstName}'s`}</h4>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px", marginTop: "-10px" }}>
                                  <div></div>
                                  <h4 className="current-one-on-one-meeting-head" style={{ textTransform: "uppercase", color: "#009AF1", margin: "0" }}>{item?.okrId?.okrType === 'KEY_RESULT' ? 'KEY RESULT' : item?.okrId?.okrType === 'MILE_STONE' ? 'MILESTONE' : item?.okrId?.okrType}</h4>
                                  <img src={bookmark_link} alt="" style={{ cursor: "pointer" }} onClick={() => redirectToOkr(item?.okrId)} />
                                </div>
                                <p className="one-on-one-agenda-title" style={{ paddingTop: "10px" }}>{item?.okr?.title}</p>
                              </div>} trigger="click">
                                <img src={okr} alt="" style={{ cursor: "pointer" }} />
                              </Popover>}

                              <Dropdown overlay={
                                <Menu style={{ borderRadius: " 6.5px", boxShadow: " -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)", width: "80px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                  <Menu.Item key="delete" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%", flexWrap: 'nowrap' }} onClick={() => RemoveAgenda(item)}><img src={delete_icon} alt="" />Remove</Menu.Item>
                                </Menu>} placement="bottomCenter"><img src={three_dot} alt="" style={{ cursor: "pointer" }} />
                              </Dropdown>

                            </div>
                          </div>
                        )) : null}
                      </>
                    }
                  </div>
                </Col>
              </Col>
            </Row>

            {
              !occurance_modal &&
              <div className={`one-on-one-bottom-div ${app_sidebar_expand ? 'one-on-one-div-margin-left' : ''}`}>
                <Button className="one-on-one-bo-back-btn" onClick={(e) => history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))}>Go Back</Button>
                <Button className="one-on-one-save-btn" type="primary" htmlType="submit" disabled={addAgenda}>Save</Button>
              </div>

            }

          </Form>
        </div>
      </Spin>
      {permission&&permission?.runpod_permission&&<MeetingAiTipContainer {...props}/>}
    </>
  )
};

export default AddOneonOneView;

const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
const CreateButton = styled(Button)`
width:150px;
height:40px;
border-radius:5px;
margin-top:10px;
`

const KeyButton = styled(Button)`
 width:150px;
 border-radius:20px;
`

const Arrow = styled(Button)`
min-width:25px !important;
height:25px !important;
padding-top:1px !important
`

const OccuranceDropdown = styled(Dropdown)`
width:100%;
border:1px solid #d9d9d9;
padding: 4px 11px;
border-radius: 2px;
cursor:pointer;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
background:#FFF;
height:40px;
border-radius:8px;
padding-top:10px;
display:flex;
justify-content:space-between;
align-items:center;
p{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`
const ExpansionCard = styled(Card)`
border:2px solid #CACACA
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`
export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
const DropdownButton = styled(Button)`
width:95%;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`