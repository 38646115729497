import { Button, Spin } from "antd";
import { VALUE_SUERVEY_PART_1, STRENGTH_PART_1, PERSONALITY_PART_1, KNOWLEDGE_PART_1 } from "../../../../config";
import CongratulationPopupView from "modules/learning-path/common/congratulation-popup-view";
import LearnInfoView from "modules/learning-path/common/info-detail-view";
import VideoPreviewView from "modules/learning-path/common/video-preview-view";
import { learningConfig } from "modules/learning-path/configs/learning-config";
import { learningPathIcons } from "modules/learning-path/icons";
import React from "react";
import SoulCompletedResponseContainer from "../containers/soul-completed-response-container";
import SoulCompletedView from "./soul-learn-complete-view";


const SoulHomeLearningView = (props) => {
    const { navigateRoute, survey_statuses, page_loading, location, getSurveyStatus, setReviewVisible } = props
    const [show_video, setShowVideo] = React.useState(false)
    const [info_detail, setInfoDetail] = React.useState()
    const [show_congracts, setShowCongracts] = React.useState(false)
    const [soul_congracts, setSoulCongracts] = React.useState()
    const [soul_learn_complete, setSoulLearnComplete] = React.useState(false)
    const scrollToDiv = () => {
        document.getElementById(findFirstTotalCountZero(survey_statuses) ? findFirstTotalCountZero(survey_statuses) : learningConfig?.soul_card_detail[learningConfig?.soul_card_detail?.length - 1]?.key).scrollIntoView({ behavior: 'smooth' });
    };
    const findfistPending = (value) => {
        let x = learningConfig?.soul_card_detail?.find(item=>item?.key==value)
        return x
    }
    const CompetedCounts = (item) => {
        if (item) {
            let countTotalOne = 0;
            Object.values(item).forEach(item => {
                if (item.totalCount > 0) {
                    countTotalOne++;
                }
            });
            return countTotalOne
        }
    }
    const findFirstTotalCountZero = (obj) => {
        for (let key in obj) {
            if (obj[key].totalCount === 0) {
                return key;
            }
        }
        return null;
    };
    React.useEffect(() => {
        if (location) {
            let status = localStorage.getItem('to_leraning')
            if (status == 'diversity_profile_completed') {
                setShowCongracts(true);
                localStorage.removeItem('to_leraning')
            }
            else if (status == 'value_completed') {
                setSoulCongracts({ id: VALUE_SUERVEY_PART_1, type: "Values" })
                localStorage.removeItem('to_leraning')
            }
            else if (status == 'strengths_completed') {
                setSoulCongracts({ id: STRENGTH_PART_1, type: "Strengths" })
                localStorage.removeItem('to_leraning')
            }
            else if (status == 'personality_completed') {
                setSoulCongracts({ id: PERSONALITY_PART_1, type: "Personalities" })
                localStorage.removeItem('to_leraning')
            }
            else if (status == 'knowledge_skills_completed') {
                setSoulCongracts({ id: KNOWLEDGE_PART_1, type: "Knowledge & Skill" })
                localStorage.removeItem('to_leraning')
            }
            else if (status == 'impact_narrative_completed') {
                setSoulLearnComplete(true)
                localStorage.removeItem('to_leraning')
            }
            else {
                localStorage.removeItem('to_leraning')
            }
        }
    }, [location])

    const closeCongrats = () => {
        setShowCongracts(false)
        localStorage.removeItem('to_leraning')
        getSurveyStatus()
    }

    const navigatePaths = (path, key) => {
        localStorage.setItem('to_leraning', key)
        navigateRoute(path)
    }
    const submitReview =() =>{
        setReviewVisible('Soul')
    }
    return (
        <div className="learning-root">
            <Spin spinning={page_loading}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                    <img src={learningPathIcons?.back_arrow} alt="back_arrow" style={{ width: "20px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                    <h4 className="learing-back-title">Soul</h4>
                </div>
                <div className="soul-banner-container" style={{ position: "relative", marginTop: "1em" }}>
                    <h1 className="learing-main-title" style={{ fontSize: "2.25em" }}>Soul <span style={{ fontWeight: "275" }}>Identity</span>
                        <img src={learningPathIcons?.soul_icon} alt="bulb_icon" style={{ width: '1.25em', marginLeft: '.5em' }} /></h1>
                    <h4 className="soul-read-timer"><img src={learningPathIcons?.timer} alt="timer" style={{ width: "1em" }} /> 1.5 Hours</h4>
                    <div style={{ width: "100%", marginTop: '1em' }}>
                        <p className="learn-description content-width" >{learningConfig?.soul_baner_content}</p>
                        <Button className="learn-active-button" style={{ marginTop: "2em" }} onClick={() => scrollToDiv()}>START</Button>
                        <img className="banner-play-icon" src={learningPathIcons.learn_play_icon} alt=""
                            onClick={() => setShowVideo({ videoUrl: learningConfig?.soul_banner_video_url,path: findfistPending(findFirstTotalCountZero(survey_statuses))?.path, key: findfistPending(findFirstTotalCountZero(survey_statuses))?.key,show_skip:true  })} />
                    </div>
                </div>
                <div className="soul-card-continer soul-card-web-vew">
                    <div className="soul-card-container-section">
                        {learningConfig?.soul_card_detail
                            ?.filter((_, index) => index % 2 === 0)
                            .map((item, index) => (
                                <div className="soul-learn-survey-card" key={index} id={item?.key}>
                                    <h4 className="landing-card-title" style={{ fontWeight: "500", fontSize: '.9em' }}>Step {index * 2 + 1}</h4>
                                    <h4 className="landing-card-title" style={{ fontSize: "1.25em" }}>{item?.title}</h4>
                                    <h4 className="soul-read-timer" style={{ fontWeight: "500" }}>
                                        <img src={learningPathIcons?.timer} alt="timer" style={{ width: "1em", marginRight: ".25em" }} />
                                        {item?.timer} Mins {item?.no_of_questions ? `| ${item?.no_of_questions} Questions` : ''}</h4>
                                    <img className="soul-learn-card-thumbnail" src={item?.thumbnail} alt="" onClick={() => setShowVideo({ videoUrl: item?.video_url, path: item?.path, key: item?.key,show_skip:false  })} />
                                    <div className="learing-space-between" style={{ marginTop: "1em" }}>
                                        {survey_statuses && survey_statuses[item?.key]?.totalCount > 0 ? <Button className="soul-learn-completed-btn">Completed</Button> :
                                            <Button
                                                className="soul-learn-take-survey-btn"
                                                disabled={
                                                    findFirstTotalCountZero(survey_statuses) == item?.key &&
                                                        survey_statuses[item?.key]?.totalCount === 0 ? false : true
                                                }
                                                onClick={() => navigatePaths(item?.path, item?.key)}
                                            >
                                                Take Survey
                                            </Button>
                                        }
                                        <img src={learningPathIcons?.learn_info_icon} alt="learn_info_icon" style={{ height: '2em', cursor: "pointer" }} onClick={() => setInfoDetail({ title: item?.title, detail: item?.info })} />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="soul-card-container-section">
                        {!page_loading && survey_statuses && CompetedCounts(survey_statuses) !== learningConfig?.soul_card_detail?.length ? <div className="soul-learn-status-card-container">
                            <div className="soul-learn-status-card">
                                <h4 className="landing-card-title" style={{ color: "#8C8CA1", fontSize: "2.5em", paddingTop: "1em" }}>{learningConfig?.soul_card_detail?.length}</h4>
                                <h4 className="landing-card-title sm-font-size" style={{ color: "#8C8CA1", textAlign: "center" }}>Total <br /> Surveys</h4>
                            </div>
                            <div className="soul-learn-status-card" style={{ position: "relative" }}>
                                <img src={learningPathIcons?.learn_poper} alt="learn_poper" style={{ position: 'absolute', top: "0em", height: '7em' }} />
                                <h4 className="landing-card-title" style={{ color: "#00000", fontSize: "2.5em", paddingTop: "2em" }}>{CompetedCounts(survey_statuses)}</h4>
                                <h4 className="landing-card-title sm-font-size" style={{ color: "#000000", textAlign: "center" }}>Completed</h4>
                            </div>
                            <div className="soul-learn-status-card">
                                <h4 className="landing-card-title" style={{ color: "#8C8CA1", fontSize: "2.5em", paddingTop: "2em" }}>{learningConfig?.soul_card_detail?.length - CompetedCounts(survey_statuses)}</h4>
                                <h4 className="landing-card-title sm-font-size" style={{ color: "#8C8CA1", textAlign: "center" }}>Upcoming</h4>
                            </div>
                        </div> :
                            !page_loading && survey_statuses && <div className="soul-learn-survey-card learing-space-between gif-bg" style={{ height: '100%', flexWrap: "nowrap", padding: "1em", justifyContent: "center", gap: '2em' }} >
                                <img className="tropy-img" src={learningPathIcons?.trophy_icon} alt="trophy_icon" style={{ height: "10em" }} />
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "1em" }}>
                                    <h2 className="landing-card-title landing-title-font-size-bold" style={{ fontSize: "2.25em" }}>Congratulations</h2>
                                    <h4 className="landing-card-title landing-title-font-size-normal" style={{ fontSize: "1.25em", textAlign: 'center' }}>You have Completed soul <br /> identity</h4>
                                    <Button className="soul-learn-take-survey-btn" onClick={()=>submitReview()}>Submit Your Review</Button>
                                </div>
                            </div>}

                        {learningConfig?.soul_card_detail?.filter((_, index) => index % 2 !== 0)?.map((item, index) => (
                            <div className="soul-learn-survey-card" id={item?.key}>
                                <h4 className="landing-card-title" style={{ fontWeight: "500", fontSize: '.9em' }}>Step {index * 2 + 2}</h4>
                                <h4 className="landing-card-title" style={{ fontSize: "1.25em" }}>{item?.title}</h4>
                                <h4 className="soul-read-timer" style={{ fontWeight: "500" }}>
                                    <img src={learningPathIcons?.timer} alt="timer" style={{ width: "1em", marginRight: ".25em" }} />
                                    {item?.timer} Mins {item?.no_of_questions ? `| ${item?.no_of_questions} Questions` : ''}</h4>
                                <img className="soul-learn-card-thumbnail" src={item?.thumbnail} alt="" onClick={() => setShowVideo({ videoUrl: item?.video_url, path: item?.path, key: item?.key,show_skip:false })} />
                                <div className="learing-space-between" style={{ marginTop: "1em" }}>
                                    {learningConfig?.soul_card_detail[index * 2 + 1]?.key != 'impact_narrative' ? <div>
                                        {survey_statuses && survey_statuses[item?.key]?.totalCount > 0 ? <Button className="soul-learn-completed-btn">Completed</Button> :
                                            <Button className="soul-learn-take-survey-btn"
                                                disabled={
                                                    findFirstTotalCountZero(survey_statuses) == item?.key &&
                                                        survey_statuses[item?.key]?.totalCount === 0 ? false : true
                                                }
                                                onClick={() => navigatePaths(item?.path, item?.key)}>
                                                {learningConfig?.soul_card_detail[index * 2 + 1]?.key == 'impact_narrative' ? 'Generate' : 'Take Survey'}
                                            </Button>}
                                    </div> :
                                        <div>
                                             {survey_statuses && survey_statuses[item?.key]?.totalCount > 0 ? 
                                             <Button className="soul-learn-take-survey-btn" onClick={() => navigatePaths(item?.path, item?.key)}>Back to Impact narrative </Button> :
                                            <Button className="soul-learn-take-survey-btn"
                                                disabled={
                                                    findFirstTotalCountZero(survey_statuses) == item?.key &&
                                                        survey_statuses[item?.key]?.totalCount === 0 ? false : true
                                                }
                                                onClick={() => navigatePaths(item?.path, item?.key)}>
                                                Generate
                                            </Button>}
                                        </div>}


                                    {item?.info!==''&&<img src={learningPathIcons?.learn_info_icon} alt="learn_info_icon" style={{ height: '2em', cursor: "pointer" }} onClick={() => setInfoDetail({ title: item?.title, detail: item?.info })} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="soul-card-mobile-view">
                    <div className="soul-card-container-section" style={{ maxWidth: "100%" }}>
                        {!page_loading && survey_statuses && CompetedCounts(survey_statuses) !== learningConfig?.soul_card_detail?.length ? <div className="soul-learn-status-card-container" style={{ maxWidth: "100%" }}>
                            <div className="soul-learn-status-card">
                                <h4 className="landing-card-title" style={{ color: "#8C8CA1", fontSize: "2.5em", paddingTop: "1em" }}>{learningConfig?.soul_card_detail?.length}</h4>
                                <h4 className="landing-card-title sm-font-size" style={{ color: "#8C8CA1", textAlign: "center" }}>Total <br /> Surveys</h4>
                            </div>
                            <div className="soul-learn-status-card" style={{ position: "relative" }}>
                                <img src={learningPathIcons?.learn_poper} alt="learn_poper" style={{ position: 'absolute', top: "0em", height: '7em' }} />
                                <h4 className="landing-card-title" style={{ color: "#00000", fontSize: "2.5em", paddingTop: "2em" }}>{CompetedCounts(survey_statuses)}</h4>
                                <h4 className="landing-card-title sm-font-size" style={{ color: "#000000", textAlign: "center" }}>Completed</h4>
                            </div>
                            <div className="soul-learn-status-card">
                                <h4 className="landing-card-title" style={{ color: "#8C8CA1", fontSize: "2.5em", paddingTop: "2em" }}>{learningConfig?.soul_card_detail?.length - CompetedCounts(survey_statuses)}</h4>
                                <h4 className="landing-card-title sm-font-size" style={{ color: "#8C8CA1", textAlign: "center" }}>Upcoming</h4>
                            </div>
                        </div> :
                            !page_loading && survey_statuses && <div className="soul-learn-survey-card learing-space-between gif-bg" style={{ height: '100%', flexWrap: "nowrap", padding: "1em", justifyContent: "center", gap: '2em' }} >
                                <img src={learningPathIcons?.trophy_icon} alt="trophy_icon" style={{ height: "12em" }} />
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "1em" }}>
                                    <h2 className="landing-card-title" style={{ fontSize: "2.25em" }}>Congratulations</h2>
                                    <h4 className="landing-card-title" style={{ fontSize: "1.25em", textAlign: 'center' }}>You have Completed soul <br /> learning path</h4>
                                </div>
                            </div>}
                        {learningConfig?.soul_card_detail?.map((item, index) => (
                            <div className="soul-learn-survey-card" style={{ width: "100%", height: "100%" }} key={index} id={item?.key}>
                                <h4 className="landing-card-title" style={{ fontWeight: "500", fontSize: '.9em' }}>Step {index + 1}</h4>
                                <h4 className="landing-card-title" style={{ fontSize: "1.25em" }}>{item?.title}</h4>
                                <h4 className="soul-read-timer" style={{ fontWeight: "500" }}>
                                    <img src={learningPathIcons?.timer} alt="timer" style={{ width: "1em", marginRight: ".25em" }} />
                                    {item?.timer} Mins {item?.no_of_questions ? `| ${item?.no_of_questions} Questions` : ''}</h4>
                                <img className="soul-learn-card-thumbnail" src={item?.thumbnail} alt="" onClick={() => setShowVideo({ videoUrl: item?.video_url, path: item?.path, key: item?.key,show_skip:false })} />
                                <div className="learing-space-between" style={{ marginTop: "1em" }}>
                                    {learningConfig?.soul_card_detail[index]?.key != 'impact_narrative' ? <div>
                                        {survey_statuses && survey_statuses[item?.key]?.totalCount > 0 ? <Button className="soul-learn-completed-btn">Completed</Button> :
                                            <Button className="soul-learn-take-survey-btn"
                                                disabled={
                                                    findFirstTotalCountZero(survey_statuses) == item?.key &&
                                                        survey_statuses[item?.key]?.totalCount === 0 ? false : true
                                                }
                                                onClick={() => navigatePaths(item?.path, item?.key)}>Take Survey
                                            </Button>}
                                    </div> :
                                        <div>
                                             {survey_statuses && survey_statuses[item?.key]?.totalCount > 0 ? 
                                             <Button className="soul-learn-take-survey-btn" onClick={() => navigatePaths(item?.path, item?.key)}>Back to Impact narrative </Button> :
                                            <Button className="soul-learn-take-survey-btn"
                                                disabled={
                                                    findFirstTotalCountZero(survey_statuses) == item?.key &&
                                                        survey_statuses[item?.key]?.totalCount === 0 ? false : true
                                                }
                                                onClick={() => navigatePaths(item?.path, item?.key)}>
                                                Generate
                                            </Button>}
                                        </div>}

                                        {item?.info!==''&&<img src={learningPathIcons?.learn_info_icon} alt="learn_info_icon" style={{ height: '2em', cursor: "pointer" }} onClick={() => setInfoDetail({ title: item?.title, detail: item?.info })} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <VideoPreviewView {...props} show_video={show_video} onClose={() => setShowVideo(null)} />
                <LearnInfoView {...props} info_detail={info_detail} onClose={() => setInfoDetail(null)} />
                <CongratulationPopupView {...props} show_view={show_congracts} onClose={() => closeCongrats()} />
                <SoulCompletedResponseContainer {...props} show_view={soul_congracts} onClose={() => { getSurveyStatus(); setSoulCongracts(null) }} />
                <SoulCompletedView {...props} show_view={soul_learn_complete} onCancel={() => { getSurveyStatus(); setSoulLearnComplete(false)}} />
            </Spin>
        </div>

    )
}

export default SoulHomeLearningView;