import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { message } from "antd"
import { CREATE_BADGE_AWARD } from '../graphql/createBadgeAwardMutation.gql';
import { DELETE_BADGE_AWARD } from '../graphql/deleteBadgeMutation.gql';
import { CREATE_BADGE_AWARD_COMMNET, CREATE_BADGE_AWARD_COMMNET_REACTION, CREATE_BADGE_AWARD_REACTION } from '../graphql/badgeReactandCommentMutation.gql';


export const withCreateBadgeAward = Component =>
graphql(CREATE_BADGE_AWARD, {
  props: ({ mutate, history }) => ({
    createBadgeAward: async values => {
      try {
        const {
          data: { createBadgeAward }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return createBadgeAward.badgeAward;
      } catch (e) {
        message.destroy();
        console.error(e);
      }
    }
  })
})(Component);

export const withDeleteBadgeAward = Component =>
graphql(DELETE_BADGE_AWARD, {
  props: ({ mutate, history }) => ({
    deleteBadgeAward: async values => {
      try {
        const {
          data: { deleteBadgeAward }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Successfully deleted');
        return deleteBadgeAward.badgeAward;
        
      } catch (e) {
        message.destroy();
        message.error("Failed to delete Badge award");
        console.error(e);
      }
    }
  })
})(Component);
export const withDeleteBadgeAwardForFeedback = Component =>
graphql(DELETE_BADGE_AWARD, {
  props: ({ mutate, history }) => ({
    deleteBadgeAward: async values => {
      try {
        const {
          data: { deleteBadgeAward }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return deleteBadgeAward.badgeAward;
        
      } catch (e) {
        message.destroy();
        message.error("Failed to delete Badge award");
        console.error(e);
      }
    }
  })
})(Component);

export const withCreateBadgeComment = Component =>
  graphql(CREATE_BADGE_AWARD_COMMNET, {
    props: ({ mutate, history }) => ({
      createBadgeAwardComment: async values => {
        try {
          const {
            data: { createBadgeAwardComment }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          return createBadgeAwardComment.badgeAwardComment;
        } catch (e) {
          message.destroy();
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateBadgeReaction = Component =>
    graphql(CREATE_BADGE_AWARD_REACTION, {
      props: ({ mutate, history }) => ({
        createBadgeAwardReaction: async values => {
          try {
            const {
              data: { createBadgeAwardReaction }
            } = await mutate({
              variables: {
                ...values
              }
            });
    
            message.destroy();
            return createBadgeAwardReaction.baReaction;
          } catch (e) {
            message.destroy();
            console.error(e);
          }
        }
      })
    })(Component);
    export const withCreateBadgeCommentReaction = Component =>
      graphql(CREATE_BADGE_AWARD_COMMNET_REACTION, {
        props: ({ mutate, history }) => ({
          createBadgeAwardCommentReaction: async values => {
            try {
              const {
                data: { createBadgeAwardCommentReaction }
              } = await mutate({
                variables: {
                  ...values
                }
              });
      
              message.destroy();
              return createBadgeAwardCommentReaction.baCommentReaction;
            } catch (e) {
              message.destroy();
              console.error(e);
            }
          }
        })
      })(Component);