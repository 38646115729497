import gql from "graphql-tag";

export const ORG_POC_SURVEY_LIST =gql`
  query allOrgSurveysByImplicitOrgId{
    allOrgSurveysByImplicitOrgId(listing:true){
        edges{
            node{
                id
                name
                 multisourceResponse
            }
        }
    }
  }
`
export const ORG_POC_VERTICAL_SURVEY_RESPONSE = gql`
query vertical($id: ID!,$survey: ID,$user_FirstName: String,$first:Int,$cursor:String,$offset: Int){
  vertical(id:$id){
    id
    name
    employees(user_FirstName:$user_FirstName,first:$first,after:$cursor,offset:$offset){
      totalCount
      pageInfo{
        hasNextPage
        endCursor
      }
      edges{
        node{
          id
          teamMembersEmployee(vertical:$id){
            edges{
              node{
                id
                name
              }
            }
          }
          teamManagerEmployee(vertical:$id){
            edges{
              node{
                id
                name
              }
            }
          }
          user{
            id
            firstName
            lastName
            employee{
              id
            }
            profile{
              id
              profileImage
            }
            responseSet(survey:$survey,last:1){
              edges{
                node{
                  id
                  responseDate
                  survey{
                    id
                    multisourceResponse
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export const TEAM_POC_ASSESSMENT_RESPONSE =gql`
query Team ($id: ID!,$survey: ID,$user_FirstName: String,$first:Int,$cursor:String,$offset: Int){
Team(id:$id){
    id
    name
    employees(user_FirstName:$user_FirstName,first:$first,after:$cursor,offset:$offset){
    totalCount
      pageInfo{
        hasNextPage
        endCursor
      }
      edges{
        node{
          id
          user{
            id
            firstName
            lastName
            employee{
              id
            }
            profile{
              id
              profileImage
            }
            responseSet(survey:$survey,last:1){
              edges{
                node{
                  id
                  responseDate
                  survey{
                    id
                    multisourceResponse
                  }
                }
              }
            }
					}
        }
      }
    }
  }
}
`
export const ORG_EMPLOYEE_LEVEL_COUNTS =gql`
  query me {
    me {
      id
      employee {
        id
        orgPocEmployee {
          edges {
            node {
              id
              employees {
                totalCount
              }
              ceo{
               id
               user{
                id
                firstName
               }
              }
              poc{
                id
                user{
                  id
                }
                verticalHeadEmployee{
                  totalCount
                }
                teamManagerEmployee{
                  totalCount
                }
              }
              verticalSet {
                totalCount
                edges {
                  node {
                    id
                    heads {
                      edges {
                        node {
                          id
                          user {
                            id
                          }
                        }
                      }
                    }
                  }
                }
                edges {
                  node {
                    teamSet {
                      totalCount
                      edges {
                        node {
                          id
                          managers {
                            edges {
                              node {
                                id
                                user {
                                  id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const ORG_SURVEY_BY_ID = gql`
  query SurveyById($id: ID!){
    SurveyById(id:$id){
    id
    name
     multisourceResponse
  }
}
`
export const ORG_VERTICAL_DETAIL=gql`
query vertical($id: ID!){
  vertical(id:$id){
  id
  name
  }
}
`
export const ORG_SURVEY_360_REPORTS = gql`
query responseByResponseId($id:ID!){
  responseByResponseId(id: $id) {
    id
    survey {
      id
      name
      responseSet(last: 1) {
        edges {
          node {
            responseDate
          }
        }
      }
    }
    report360
  }
}
`;

export const ORG_MANAGER_EMPLOYEE_COUNT = gql`
  query me {
    me {
     id
     employee{
        id
        teamManagerEmployee{
            totalCount
        }
        orgCeoEmployee{
            totalCount
        }
        orgPocEmployee{
            totalCount
        }
        verticalHeadEmployee{
            totalCount
        }
     }
    }
  }
`;

export const ORG_USER_DETAIL = gql`
query employeeById($id:ID!) {
  employeeById(id:$id) {
    id
    user{
    id
    firstName
    lastName
    }
    
  }
}
`
export const TEAM_ASSESSMENT_REPORTS = gql`
query me($user: ID,$id: ID) {
    teamMemberReport:me{
        id
        employee{
          id
          organizationSet{
            edges{
              node
              {
                id
                employees(user:$user){
                  edges{
                    node{
                      id
                      user{
                        id
                        firstName
                        responseSet(id:$id){
                          edges{
                            node{
                              id
                              report360
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;
export const SURVEY_BY_ID_FOR_REPORT = gql`
query SurveyById($id:ID!){
    SurveyById(id:$id){
        id
        name
    }
}
`;
export const POC_MANAGER_EMPLOYEE_DETAIL = gql`
  query me {
   me {
     id
     employee{
        id
        teamManagerEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
        orgCeoEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
        orgPocEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
        verticalHeadEmployee{
            totalCount
            edges{
                node{
                  id
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                          email
                          employee{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
     }
    }
  }
`;
export const ASSESSMENT_RESPONSE_SET_FOR_EMPLOYEE = gql`
  query me($user: ID,$survey: ID) {
    responseSetList:me{
        id
        employee{
          id
          organizationSet{
            edges{
              node
              {
                id
                employees(user:$user){
                  edges{
                    node{
                      id
                      user{
                        id
                        firstName
                        responseSet(survey_Multisourceresponse:true,survey:$survey){
                          edges{
                            node{
                              id
                              survey{
                                id
                                name
                              }
                              responseDuration
                              responseDate
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;

export const VERTICAL_TOTAL_COUNT =gql`
query vertical{
  verticals{
    totalCount
	}
}
`

export const LISCENCING_DETAIL =gql`
  query licenses_detail($organization: ID,$id: ID!){
    licenses:licenses(organization:$organization){
      edges{
        node{
          plan{
            name
            licenses
          }
          expiry
        }
      }
    }
    organization:organizationById(id:$id){
      id
      employees{
        totalCount
      }
  }
  }
`


export const ANALYTICS =gql`
  query analytics{
    analytics {
      analyticsData
    }
  }
`

export const ORG_POC_SURVEYS =gql`
  query allOrgSurveysByImplicitOrgId{
    allOrgSurveysByImplicitOrgId{
        edges{
            node{
                id
                name
                 multisourceResponse
            }
        }
    }
  }`

export const ORG_POC_SURVEY_RESPONSE_SET =gql`
query surveyResponses($survey: ID,$cursor:String,$first:Int){
  surveyResponses(survey:$survey,after:$cursor,first:$first){
    pageInfo{
      hasNextPage
      endCursor
    }
    edges{
      node{
        id
        survey{
          id
          groupSet{
            edges{
              node{
                id
                questionSet{
                  totalCount
                }
              }
            }
          }
        }
        user{
          id
          firstName
          lastName
          email
        }
        responseDate
        answerSet{
          edges{
            node{
              id
              answer
              question{
                id
                questionText
                choiceSet(answerKey:true){
                  edges{
                    node{
                      id
                      value
                      answerKey
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`