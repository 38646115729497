import React from "react";
import { Button, Select, Spin, Modal } from 'antd'
import { dasboardIcons } from "../dashboard-icons";
import GiveFeedbackContainer from "../containers/giveFeedbackContainer";
import styled from 'styled-components';
import { ReactMarkdown, displayUtcToUserTimezone } from "modules/look";
import IssueNewBadgeContainer from "modules/kudos-user/container/issueNewBadgeContainer";
import AwardDetailPopup from "modules/kudos-user/container/awardDetaailPopup";
import FeedbackDetailView from "modules/feedback/components/ViewFeedbackPopUp";
import GiveKudosNewPopupContainer from "modules/kudos-user/container/giveKudosNewPopupContainer";

const KudosAndFeedbackView = ( props ) => {

    const { feedbackPermission, recievedAwardList, me, userPermission, user_permission, feedback_detail_loading, feedback_details,getFeedbackDetails } = props
    const [ show_feedback, setShowFeedback ] = React.useState( true )
    const [ filter, setSelectFilter ] = React.useState( 'all' )
    const [ give_kudos, setGiveKudos ] = React.useState( false )
    const [ selected_kudos_detail, setSelectedKudosDetail ] = React.useState( false )
    const [ selectedAward, setSelectedAward ] = React.useState()
    const [ feedbackDetailsModalVisible, setfeedbackDetailsModalVisible ] = React.useState( false )
    const [selected_notify,setSelectedNotify] = React.useState()
    const handleFilterChange = ( e ) => {
        if ( e ) {
            setSelectFilter( e )
        }
    }

    return (
        <>
            <div className="small-kudos-container">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em", flexWrap: "wrap" }}>
                    {user_permission?.award_badge_permission && <Button className="user-board-welcome-banner-button" style={{ height: "2.5em", fontSize: "1.1em", marginTop: '.75em' }} onClick={() => setGiveKudos( true )}>GIVE KUDOS</Button>}
                    {feedbackPermission && <GiveFeedbackContainer feedbackPermission={feedbackPermission} {...props} />}
                </div>
                {( recievedAwardList?.badges?.length > 0 || recievedAwardList?.feedbacks?.length > 0 ) && <div style={{ display: 'flex', flexDirection: "column", justifyContent: "start", gap: '1em', marginTop: "1em" }}>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', gap: '1em', alignItems: "center" }}>
                        <h4 className="user-dashboard-small-card-title">Received kudos and feedback</h4>
                        <img src={dasboardIcons?.down_arrow} style={{ width: "20px", transform: show_feedback ? "rotate(0deg)" : "rotate(-90deg)", cursor: "pointer", transition: ".5s" }} alt="" onClick={() => setShowFeedback( !show_feedback )} />
                    </div>
                    {show_feedback && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".5em" }}>
                        <div style={{ width: '100%', height: '.5px', background: "rgba(137, 152, 162, 1)" }}></div>
                        <CustomSelect dropdownClassName="content-dropdown" onChange={( e ) => handleFilterChange( e )} defaultValue={filter}>
                            <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3',width: "10em",fontFamily:"Poppins",fontWeight:500 }} key='all' value='all' >All</Select.Option>
                            <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3',width: "10em",fontFamily:"Poppins",fontWeight:500  }} key='kudos' value='kudos' >Kudos</Select.Option>
                            <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3',width: "10em",fontFamily:"Poppins",fontWeight:500  }} key='feedback' value='feedback' >Feedback</Select.Option>
                        </CustomSelect>
                    </div>}
                    {show_feedback && <div style={{ overflow: "auto", height: '28em', display: "flex", flexDirection: "column", gap: '1em' }}>
                        {recievedAwardList?.badges?.length > 0 && ( filter === 'all' || filter === 'kudos' ) && recievedAwardList?.badges?.map( item => (
                            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                                    <div className="text-transform-capitalize" style={{ display: "flex", justifyContent: 'center', alignItems: "center", background: "#C9D0DA", width: "3em", height: '3em', borderRadius: "50%", fontFamily: "Poppins", fontWeight: "500", fontSize: "1.2em" }}>
                                        {me?.employee?.user?.firstName?.charAt( 0 )}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                                        <h4 className="dashboard-kudos-heading text-transform-capitalize" style={{ fontWeight: "500" }}>Kudos for {me?.employee?.user?.firstName}</h4>
                                        <h4 className="dashboard-kudos-heading" style={{ fontSize: ".85em", fontWeight: "400" }}>AWARDED BY: <span className="text-transform-capitalize">{item?.issuer?.user?.firstName}</span> |  {displayUtcToUserTimezone( item?.createdAt, "DD MMM YYYY" )} </h4>
                                    </div>
                                </div>
                                {item?.badge && <div className="dashboard-kudos-image-container" style={{ cursor: "pointer" }} onClick={() => { setSelectedKudosDetail( true ); setSelectedAward( item ) }}>
                                    <img style={{ height: "8em", width: "8em" }} src={item?.badge?.image} alt="" />
                                    <h4 className="dashboard-kudos-heading" style={{ fontWeight: "600" }}>{item?.badge?.title}</h4>
                                    <p className="dashboard-kudos-heading text-overflow-elipsis" style={{ fontWeight: "400", fontSize: ".9em", }}>
                                        {item?.badge?.behaviouralDescription}
                                        {/* {item?.badge?.behaviouralDescription?.length>100&&<span style={{ display: 'block', fontWeight: "500", fontSize: ".9em", cursor: "pointer" }}>See more</span>} */}
                                    </p>
                                </div>}
                            </div>
                        ) )}
                        {recievedAwardList?.feedbacks?.length > 0 && ( filter === 'all' || filter === 'feedback' ) && recievedAwardList?.feedbacks?.map( item => (
                            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                                    <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", background: "#C9D0DA", width: "3em", height: '3em', borderRadius: "50%", fontFamily: "Poppins", fontWeight: "500", fontSize: "1.2em" }}>
                                        {me?.employee?.user?.firstName?.charAt( 0 )}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                                        <h4 className="dashboard-kudos-heading" style={{ fontWeight: "500" }}>Feedback for <span className="text-transform-capitalize">{me?.employee?.user?.firstName}</span></h4>
                                        <h4 className="dashboard-kudos-heading" style={{ fontSize: ".85em", fontWeight: "400" }}>AWARDED BY: <span className="text-transform-capitalize">{item?.feedbackFrom?.user?.firstName}</span> |  {displayUtcToUserTimezone( item?.createdAt, "DD MMM YYYY" )} </h4>
                                    </div>
                                </div>
                                {item?.response?.answerSet?.edges?.length > 0 && <div className="dashboard-kudos-image-container" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em",cursor:"Pointer" }} onClick={()=>{getFeedbackDetails(item?.id);setfeedbackDetailsModalVisible(true)}}>
                                    {item?.badgeAward?.edges?.length > 0 && <div style={{ display: 'flex', flexDirection: "column", justify: "center", alignItems: "center", gap: '1em' }}>
                                        <img style={{ height: "8em", width: "8em" }} src={item?.badgeAward?.edges[ 0 ]?.node?.badge?.image} alt="" />
                                        <h4 className="dashboard-kudos-heading" style={{ fontWeight: "600" }}>{item?.badgeAward?.edges[ 0 ]?.node?.badge?.title}</h4>
                                    </div>}
                                    <div style={{ display: 'flex', flexDirection: "column", justify: "start", alignItems: "center" }}>
                                        <p className="dashboard-kudos-heading text-overflow-elipsis" style={{ fontWeight: "400", fontSize: ".9em" }}>
                                            <ReactMarkdown>{item?.response?.answerSet?.edges[ 0 ]?.node?.answer}</ReactMarkdown>
                                            {/* <span style={{ fontWeight: "500", fontSize: ".9em", cursor: "pinter" }}> See more</span> */}
                                        </p>
                                    </div>
                                </div>}
                            </div>
                        ) )}
                    </div>}
                </div>}

                {show_feedback && recievedAwardList?.badges?.length === 0 && recievedAwardList?.feedbacks?.length === 0 && <div className='no-performance-container' style={{ height: "28em", marginTop: '1em' }}>
                    <img src={dasboardIcons?.kudos_no_data_bg} alt="" />
                    <h4 className='baner-tip-title' style={{ textAlign: "center" }}>Check out all your Kudos here! Keep <br></br> the excitement going!</h4>
                </div>}
            </div>
            {user_permission?.award_badge_permission && give_kudos && 
            <GiveKudosNewPopupContainer {...props} userPermission={userPermission} visible={give_kudos} onCancel={(e) => { setGiveKudos(false) }} />
            // <IssueNewBadgeContainer userPermission={userPermission} show_popup={give_kudos} onCancel={() => setGiveKudos( false )} {...props} />
            }
            
            {
                selected_kudos_detail && <AwardDetailPopup selectedAward={selectedAward} onClose={( e ) => { setSelectedKudosDetail( e ); setSelectedAward() }} visible={selected_kudos_detail} />
            }

            <Modal visible={feedbackDetailsModalVisible}
                footer={false}
                centered
                destroyOnClose={true}
                closable={false}
            >
                <Spin spinning={feedback_detail_loading}>
                    <FeedbackDetailView feedbackPermission={feedbackPermission} feedbackDetails={feedback_details} setFeedbackModalVisible={(e)=>setfeedbackDetailsModalVisible(e)}setFeedbackPreviewDisplay={(e)=>setfeedbackDetailsModalVisible(e)} feedbackPreviewDisplay={false} setFeedbackDetails={(e)=>{}} me={me} />
                </Spin>
            </Modal>
        </>


    )
}

export default KudosAndFeedbackView

const CustomSelect = styled( Select )`
.ant-select-selector{
    color:  #A3A3A3;
    font-size: 1em;
    font-family: 'Poppins';
    line-height: normal;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
    width:7em !important;
}
`;